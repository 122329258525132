import React, { useEffect, useState } from "react";
import {
  Cog6ToothIcon,
  UserIcon,
  CogIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";

import ProfileCourses from "./ProfileCourses";
import { Routes, Route, useNavigate, NavLink } from "react-router-dom";

const Profile = ({ user }) => {
  const [userMissedPoint, setUserMissedPoint] = useState(0);

  useEffect(() => {
    console.log(`user`);
    console.log(user);
    let totalCount = 0;
    if (user != null) {
      if (user.name == null) {
        totalCount += 1;
      }
      if (user.surname == null) {
        totalCount += 1;
      }
      if (user.email == null) {
        totalCount += 1;
      }
      if (user.birthday == null) {
        totalCount += 1;
      }
      if (user.homeTown == null) {
        totalCount += 1;
      }
      if (user.phone == null) {
        totalCount += 1;
      }
      if (user.photoUrl == null) {
        totalCount += 1;
      }
      console.log("totalCount");
      console.log(totalCount);
      setUserMissedPoint(totalCount);
    }
  }, [user]);

  function calculateAge(birthdate) {
    if (birthdate) {
      const currentDate = new Date();
      const birthDate = new Date(birthdate);

      let age = currentDate.getFullYear() - birthDate.getFullYear();

      // Check if the birthday has occurred this year
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    } else {
      return null;
    }
  }

  return user !== null ? (
    <div>
      {/* <div className="border-black border-solid border-2">
        <div className="flex flex-row">
          {user.photoUrl ? (
            <img
              src={user.photoUrl}
              alt="Fetched"
              className="w-32 h-32 rounded-full m-3"
            />
          ) : (
            <div className="bg-gray-200 w-auto h-full text-3xl rounded-full p-10 -mb-6 m-4">
              <img src="" />
              <UserIcon className="h-10 w-10" />
            </div>
          )}

          <div className="grid grid-cols-4 grid-rows-2 ml-40 my-4 -mb-6 w-full text-xl font-medium">
            <p className="col-start-1">
              {user.name} {user.surname}
            </p>
            <p className="col-start-2">
              {user.birthday ? calculateAge(user.birthday) : `Yas`}{" "}
            </p>
            <p>{user.occupation ? user.occupation : `Meslek`}</p>
            <p className="row-start-2 col-start-1">
              {user.homeTown ? user.homeTown : `Memleket`}
            </p>
          </div>
        </div>

        <div className="h-10 w-full flex justify-end text-white stroke-black stroke-1 my-2">
          {userMissedPoint != 0 ? (
            <div className="relative inline-block">
              <NavLink to="/profileSettings">
                <button className="h-full w-10 hover:text-black  mr-4">
                  <Cog6ToothIcon />
                </button>
              </NavLink>

              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 py-1 mr-2 text-xs">
                {userMissedPoint}
              </span>
            </div>
          ) : (
            <div>
              <NavLink to="/profileSettings">
                <button className="h-full w-10 hover:text-black  mr-4">
                  <Cog6ToothIcon />
                </button>
              </NavLink>
            </div>
          )}

          <div></div>
        </div>
      </div> */}
      <div className="m-3"></div>

      {/* <div className="bg-gradient-to-r from-red-500 to-red-100 p-4 rounded-xl shadow-lg flex items-center justify-between h-44 border border-gray-400">
        <div className="flex items-center space-x-6">
          {user.photoUrl ? (
            <img
              src={user.photoUrl}
              alt="Fetched"
              className="w-32 h-32 rounded-full m-3"
            />
          ) : (
            <div className="bg-gray-200 w-auto h-full text-3xl rounded-full p-10 -mb-6 m-4">
              <UserCircleIcon className="h-16 w-16 text-gray-800" />
            </div>
          )}

          <div className="flex flex-col justify-center">
            <h2 className="text-xl font-bold text-gray-800">
              {user.name} {user.surname}
            </h2>
            <div className="flex space-x-10 mt-2">
              <p className="text-base text-gray-700">
                Yaş:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.birthday ? calculateAge(user.birthday) : `Girilmemiş`}
                </span>
              </p>
              <p className="text-base text-gray-700">
                Meslek:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.occupation ? user.occupation : <span>Girilmemiş</span>}
                </span>
              </p>
              <p className="text-base text-gray-700">
                Memleket:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.homeTown ? user.homeTown : <span>Girilmemiş</span>}
                </span>
              </p>
            </div>
          </div>
        </div>
        <button className="relative mr-10">
          <NavLink to="/profileSettings">
            <CogIcon className="h-8 w-8 text-gray-700 hover:text-gray-900" />
            {userMissedPoint !== 0 && (
              <span className="flex absolute h-3 w-3 top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75">
                  {userMissedPoint}
                </span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
              </span>
            )}
          </NavLink>
        </button>
      </div> */}
      <div className="bg-gradient-to-r from-red-500 to-red-100 p-4 rounded-xl shadow-lg flex flex-col sm:flex-row items-center sm:justify-between h-auto sm:h-44 border border-gray-400 w-full">
        <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6 w-full sm:w-auto">
          {user.photoUrl ? (
            <img
              src={user.photoUrl}
              alt="Fetched"
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-full m-3"
            />
          ) : (
            <div className="bg-gray-200 w-24 h-24 sm:w-auto sm:h-full text-3xl rounded-full p-10 -mb-6 m-4">
              <UserCircleIcon className="h-16 w-16 text-gray-800" />
            </div>
          )}

          <div className="flex flex-col justify-center text-center sm:text-left">
            <h2 className="text-lg sm:text-xl font-bold text-gray-800">
              {user.name} {user.surname}
            </h2>
            <div className="flex flex-col sm:flex-row sm:space-x-10 mt-2 space-y-2 sm:space-y-0">
              <p className="text-sm sm:text-base text-gray-700">
                Yaş:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.birthday ? calculateAge(user.birthday) : `Girilmemiş`}
                </span>
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                Meslek:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.occupation ? user.occupation : <span>Girilmemiş</span>}
                </span>
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                Memleket:{" "}
                <span className="text-gray-900 font-semibold">
                  {user.homeTown ? user.homeTown : <span>Girilmemiş</span>}
                </span>
              </p>
            </div>
          </div>
        </div>

        <button className="relative mt-4 sm:mt-0 sm:mr-10">
          <NavLink to="/profileSettings">
            <CogIcon className="h-6 w-6 sm:h-8 sm:w-8 text-gray-700 hover:text-gray-900" />
            {userMissedPoint !== 0 && (
              <span className="flex absolute h-3 w-3 top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75">
                  {userMissedPoint}
                </span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
              </span>
            )}
          </NavLink>
        </button>
      </div>
      <br />

      <hr />
      <br />

      <h1 className="text-5xl font-medium">Kurslar</h1>
      <br />
      {user.courses.length !== 0 ? (
        user.courses.map((item) => (
          <>
            <ProfileCourses item={item} />
          </>
        ))
      ) : (
        <div>
          Su anda bir kursa kayitli degilsiniz. Lutfen bir kursa kaydolunuz ve
          burada kurslariniz gozukecektir.
        </div>
      )}
    </div>
  ) : (
    <div>User blunumadı</div>
  );
};
export default Profile;
