// WriterForm.js
import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ContentCreator from "./ContentCreator2";

const SummaryContent = ({ chunkItem }) => {
	const [title, setTitle] = useState(null);
	const [paragraphs, setParagraphs] = useState([]);
	const [currentInput, setCurrentInput] = useState("");
	const [readingChunkItem, setReadingChunkItem] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		setCurrentInput(e.target.value);
	};

	const addParagraph = () => {
		if (currentInput.trim() !== "") {
			setParagraphs([...paragraphs, currentInput]);
			setCurrentInput("");
		}
	};

	// const handleContentChange = (e) => {
	// 	setContent(e.target.value);
	// };

	const handleTitleChange = (e) => {
		setTitle(e.target.value);
	};

	useEffect(() => {
		if (chunkItem.summaryContent !== null) {
			setLoading(true);
			getReading(chunkItem.summaryContent.link);
		} else {
			setReadingChunkItem(null);
			setParagraphs([]);
			setTitle(null);
		}
		console.log("Reading content chunkItem");
		console.log(chunkItem);
	}, [chunkItem]);

	const getReading = async (link) => {
		console.log(`link`);
		console.log(link);
		const response = await fetch(link);
		const data = await response.json();
		setReadingChunkItem(chunkItem.summaryContent);
		setParagraphs(data);
		setLoading(false);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const data = {
			title: title,
			content: paragraphs,
			chunkItemId: chunkItem.id,
		};

		console.log("data handle submit");
		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/summaryContents`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				console.log("Success:", response);
				setReadingChunkItem(response);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});

		setLoading(false);
	};

	const deleteHandler = async () => {
		setLoading(true);
		console.log(`id`);

		fetch(`${process.env.REACT_APP_API_URL}/summaryContents/${readingChunkItem.id}`, {
			method: "delete",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			//.then((response) => console.log(response.json()))
			.then((response) => {
				console.log("Success:", response);
				setReadingChunkItem(null);
				setParagraphs([]);
				setTitle(null);
				// chunkItem.videoContent == null
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});

		setLoading(false);
	};

	// const renderContentWithNumbers = (contentList, path = []) => {
	// 	return contentList.map((item, index) => {
	// 		const currentPath = [...path, index + 1];
	// 		const prefix = currentPath.join(".");

	// 		return (
	// 			<div key={index} className='mb-2 '>
	// 				{item.type === "header" && <h1 className='text-2xl text-red-400'>{`${prefix}-) ${item.text}`}</h1>}
	// 				{item.type === "subheader" && <h2 className='text-xl text-blue-400 ml-2'>{`${prefix}-)  ${item.text}`}</h2>}
	// 				{item.type === "paragraph" && <p className='text-black ml-5'>{`${prefix}-) ${item.text}`}</p>}
	// 				{item.children && item.children.length > 0 && renderContentWithNumbers(item.children, currentPath)}
	// 			</div>
	// 		);
	// 	});
	// };

	return (
		<>
			<div>Okuma Metni</div>
			<div>
				<span>İçerik - </span>
				<span>{chunkItem.orderNo}</span>
			</div>
			{loading == true ? (
				<div>Loading</div>
			) : readingChunkItem !== null ? (
				<div className='flex flex-col justify-center'>
					<div className='flex bg-red-300 w-fit cursor-pointer' onClick={deleteHandler}>
						Delete
						<span>
							<XMarkIcon className='h-6 w-6' />
						</span>
					</div>
					<div className='text-2xl m-3 bg-red-300 w-1/2'>{readingChunkItem.title}</div>

					<div className='text-base w-1/2 p-3 m-3 h-auto'>
						{paragraphs.map((item) => (
							<div className=' m-2 bg-blue-200 break-words'>{item}</div>
						))}
					</div>
					{/* {renderContentWithNumbers(content)} */}
				</div>
			) : (
				<div onSubmit={handleSubmit} className='w-[512px] mx-auto mt-8 p-6  rounded-md shadow-md'>
					{/* <ContentCreator setContent={setContent} content={content} /> */}
					<div>
						<label>Title:</label>
						<input
							type='text'
							value={title}
							onChange={handleTitleChange}
							placeholder='lüfen başlık giriniz'
							className='p-2 m-1 bg-green-50'
						/>
					</div>
					<div>
						<textarea
							value={currentInput}
							onChange={handleInputChange}
							className='w-full mt-2 p-2 border rounded-md h-80'
							placeholder='Paragrafınızı yazın...'
						/>
						<button
							onClick={addParagraph}
							className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:shadow-outline-green'
						>
							Paragraf Ekle
						</button>
					</div>
					<div className='text-red-200 text-3xl m-2'>{title}</div>
					{paragraphs.map((para, index) => (
						<div className=' bg-red-200 h-auto m-2' key={index}>
							<div className='h-auto bg-blue-200 overflow-hidden break-words'> {para}</div>
						</div>
					))}
					{/* <label className='block mb-4'>
						<span className='text-gray-700'>Paragraf1</span>
						<textarea
							value={content}
							onChange={handleContentChange}
							className='w-full mt-2 p-2 border rounded-md h-80'
						/>
					</label> */}
					<button
						onClick={handleSubmit}
						className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:shadow-outline-green'
					>
						Kaydet
					</button>
				</div>
			)}
		</>
	);
};

export default SummaryContent;
