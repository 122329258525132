import React, { useState, useEffect } from "react";
import ChunkAdd from "./ChunkAdd";
import ChunkTek from "./ChunkTek";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Modal from "../UI/Modal";

const Chunks = ({ sectionId }) => {
  const [newItemShow, setNewItemShow] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [chunk, setChunk] = useState(null);

  useEffect(() => {
    getSectionChunks();
  }, [newItemShow]);

  const getSectionChunks = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chunks/${sectionId}/section`
    );
    const data = await response.json();
    setChunks(data);
    console.log(`chunks with sectionID ${sectionId}`);
    console.log(data);
  };

  useEffect(() => {
    if (chunk !== null) {
      setNewItemShow(true);
    }
  }, [chunk]);

  return (
    <>
      <div className="flex flex-row gap-1">
        <div className="border  border-l-red-400 "></div>
        <div className="flex">
          <div className=" flex flex-col ">
            <div className="flex justify-center">
              <div className="text-3xl flex flex-row gap-4">
                Konular{" "}
                <span
                  className="flex flex-col justify-end"
                  onClick={() => {
                    setChunk(null);
                    setNewItemShow(true);
                  }}
                >
                  <PlusCircleIcon className="text-red-400 h-6 w-6" />
                </span>
              </div>
            </div>
            <div>
              {chunks.length > 0 ? (
                chunks.map((item) => (
                  <ChunkTek item={item} setChunk={setChunk} />
                ))
              ) : (
                <div className="m-4 p-4">
                  Lütfen artıya basarak konu ekleyiniz.
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row justify-center ">
              {/* <button
								onClick={() => {
									setNewChunkShow(!newChunkShow);
								}}
								className='w-32 bg-green-400 m-4 rounded-md p-1'
							>
								Konu Ekle
							</button> */}
            </div>
            {newItemShow && (
              <Modal
                onClose={() => {
                  setNewItemShow(false);
                  setChunk(null);
                }}
                title={
                  chunk != null
                    ? `${chunk.orderNo}- Konu Güncelle`
                    : `Konu Ekleme`
                }
              >
                <ChunkAdd
                  setNewItemShow={setNewItemShow}
                  sectionId={sectionId}
                  chunk={chunk}
                />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chunks;
