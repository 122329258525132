import React, { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";
import Modal from "../UI/Modal";
import { useNavigate } from "react-router-dom";

const TeacherApplicationForm = () => {
	const navigate = useNavigate();
	const [formSend, setFormSend] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		surname: "",
		email: "",
		birthday: "",
		homeTown: "",
		occupation: "",
		phone: "",
		role: "Teacher",
	});

	const { data, error, loading, sendRequest } = useHttp();

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		console.log(data);
	}, [data]);

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Form Data:", formData);
		sendRequest("/users", "POST", formData);
		setFormSend(true);
	};
	const handleModal = () => {
		setFormData(false);
		navigate("/");
	};
	// return (
	// 	<>
	// 		<iframe
	// 			src='https://docs.google.com/forms/d/e/1FAIpQLSfPNCqo_BeeiEYHmUTC8ilxgLC5RIymAPm439rBG6kfdEkIqg/viewform?embedded=true'
	// 			width='1000'
	// 			height='400'
	// 			frameborder='0'
	// 			marginheight='0'
	// 			marginwidth='0'
	// 		>
	// 			Yükleniyor…
	// 		</iframe>
	// 	</>
	// );

	return (
		<>
			{formSend && (
				<Modal onClose={handleModal}>
					<div className='text-2xl text-red-300'>Başvurunuz Gönderildi</div>
					<div className='text-xl text-gray-400'>
						Başvurunuz incelendikten sonra onaylanması halinde size{" "}
						<span className='font-bold text-md'>onay maili</span> gönderilecektir. Gönderilecek olan mail içerisindeki
						link ile şifrenizi belirliyerek giriş yapabilirsiniz.
					</div>
					<div className='flex justify-center item-center'>
						<button className='button-green' onClick={handleModal}>
							Tamam
						</button>
					</div>
				</Modal>
			)}
			<div className='p-4 max-w-md mx-auto'>
				<div className='font-semibold text-3xl m-4'>Öğretmen başvuru formu</div>
				<form onSubmit={handleSubmit} className='space-y-4'>
					<div>
						<label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
							Adınız
						</label>
						<input
							type='text'
							name='name'
							id='name'
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
							Soyadınız
						</label>
						<input
							type='text'
							name='surname'
							id='surname'
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='email' className='block text-sm font-medium text-gray-700'>
							Email Adresiniz
						</label>
						<input
							type='email'
							name='email'
							id='email'
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='birthday' className='block text-sm font-medium text-gray-600'>
							Birthday:
						</label>
						<input
							type='date'
							id='birthday'
							name='birthday'
							value={formData.birthday}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='homeTown' className='block text-sm font-medium text-gray-600'>
							Memleket:
						</label>
						<input
							type='text'
							id='homeTown'
							name='homeTown'
							value={formData.homeTown}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='occupation' className='block text-sm font-medium text-gray-600'>
							Meslek:
						</label>
						<input
							type='text'
							id='occupation'
							name='occupation'
							value={formData.occupation}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='phone' className='block text-sm font-medium text-gray-600'>
							Phone:
						</label>
						<input
							placeholder='Başında 0 olmadan 5382892872 gibi yazınınız.'
							type='tel'
							id='phone'
							name='phone'
							value={formData.phone}
							onChange={handleChange}
							className='mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500'
						/>
					</div>
					<button
						type='submit'
						className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					>
						Başvuru Yap
					</button>
				</form>
			</div>
		</>
	);
};

export default TeacherApplicationForm;
