import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";

const ConfirmAccountMail = () => {
	const navigate = useNavigate();
	const authCtx = useContext(AuthContext);

	const params = useParams();
	const { id } = params;

	const { data, error, loading, sendRequest } = useHttp();

	useEffect(() => {
		sendRequest(`/mail/confirm-account/${id}`);
	}, [id, sendRequest]);

	useEffect(() => {
		if (data != null) {
			console.log(data);
			authCtx.login(data.token);
		}
	}, [data]);

	return (
		<>
			{loading != true ? (
				<div>
					<div className='bg-green-400 p-2 m-2'>Onaylandı</div>
				</div>
			) : (
				<div>loading</div>
			)}
		</>
	);
};

export default ConfirmAccountMail;
