// WriterForm.js
import React, { useState, useEffect } from "react";

const Reading = ({ chunkItem }) => {
	const [paragraphs, setParagraphs] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		getReading(chunkItem.readingContent.link);
		console.log("Reading content chunkItem");
		console.log(chunkItem);
	}, []);

	const getReading = async (link) => {
		console.log(`link`);
		console.log(link);
		const response = await fetch(link);
		const data = await response.json();
		setParagraphs(data);
		setLoading(false);
	};
	return (
		<>
			<div className='max-w-2xl mx-auto my-8 p-4 bg-white shadow-md rounded-md'>
				<h1 className='text-3xl font-bold mb-4'>{chunkItem.readingContent.title}</h1>
				<div className='text-base w-1/2 p-3 m-3 h-auto'>
					{loading == true ? (
						<div>Loading</div>
					) : (
						paragraphs.map((item) => <div className=' m-2 bg-blue-200 break-words'>{item}</div>)
					)}
					{}
				</div>
			</div>
		</>
	);
};

export default Reading;
