import React, { useState } from "react";

const Student = ({ user }) => {
	const [showStudentDetail, setShowStudentDetail] = useState(false);

	return (
		<>
			<div
				className='p-1 border-b border-blue-200 w-auto mx-10'
				onClick={() => {
					setShowStudentDetail(!showStudentDetail);
				}}
			>
				{user.userCourseUser.name}
				{showStudentDetail && (
					<div className='border border-blue-300 h-auto rounded-lg m-4 '>
						<div>Ceza puani: {user.penaltyPoint}</div>
						<div>Mentorluk aliyor mu: {user.isMentie}</div>
						<div>Mentor mu: {user.penaltyPoint}</div>
						<div>Kurs tamlandi mi: {user.penaltyPoint}</div>
						<div>Mentorluk tamlandi mi: {user.mentoringComplete}</div>
                        <div>---</div>
                        <div>Email: {user.userCourseUser.email}</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Student;
