import React, { useEffect, useState, useContext } from "react";
const Video = ({ item }) => {
	console.log(item);

	return (
		<>
			{/* <div className='flex flex-row justify-center'>
				<div className='bg-green-500 inline'>
					{item.videoContent !== null ? (
						<video className='w-72 h-52' controls>
							<source src={item.videoContent.url} type='video/mp4' />
							URL kaybolmus galiba.
						</video>
					) : (
						<div>URL yok</div>
					)}{" "}
					<h1>{}</h1>
				</div>
			</div> */}
			<div className='flex items-center justify-center'>
				<div className='max-w-2xl p-8 bg-gray-100 rounded shadow-lg'>
					<h2 className='text-2xl font-bold mb-4'>{item.videoContent.name}</h2>
					<div className='aspect-w-36 aspect-h-9'>
					<video className='w-[750px] h-[200px]' controls>
							<source src={item.videoContent.url} type='video/mp4' />
							URL kaybolmus galiba.
						</video>
					</div>
				</div>
			</div>

			{/* <div className='flex flex-row justify-end'>
				<div className='flex flex-col'>
					<div className='text-3xl border rounded-3xl p-2 bg-green-400'>Tamamladı</div>
					<div className='text-3xl border rounded-3xl p-2 bg-amber-400'>Tamamlandı</div>
				</div>
			</div>

			<div className='border-4 border-black'>
				<div className='m-10'>
					<div className='font-bold text-3xl mb-10'>Özet</div>
					<div>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, officia expedita quibusdam provident
						veritatis at sit itaque quod dolor veniam eligendi id saepe! Commodi totam velit provident. Totam tempore
						voluptatum sequi quod repudiandae placeat fuga accusantium unde voluptatibus facilis modi animi quasi,
						dolores eius accusamus cumque, sed consectetur iste autem?
					</div>
				</div>
			</div> */}
		</>
	);
};
export default Video;
