import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import Teacher from "../components/Admin/Teacher";
import Courses from "../components/Admin/Courses";
import Students from "../components/Admin/Students";
import Category from "../components/Categories/Category";

const AdminPage = () => {
  const [showPage, setShowPage] = useState(1);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    console.log(authCtx);
  }, [authCtx]);

  return (
    <div className="flex flex-col md:flex-row h-auto">
      {/* Sidebar */}
      <div className="flex flex-col w-full md:w-1/4 h-auto md:h-screen bg-blue-50 cursor-pointer">
        <div className="flex flex-col h-auto md:h-screen w-full md:w-40">
          <div
            onClick={() => setShowPage(1)}
            className={`p-1 ${showPage === 1 ? "bg-blue-400" : ""}`}
          >
            Kurumlar
          </div>
          <div
            onClick={() => setShowPage(2)}
            className={`p-1 ${showPage === 2 ? "bg-blue-400" : ""}`}
          >
            Öğretmenler
          </div>
          <div
            onClick={() => setShowPage(3)}
            className={`p-1 ${showPage === 3 ? "bg-blue-400" : ""}`}
          >
            Kurslar
          </div>
          <div
            onClick={() => setShowPage(4)}
            className={`p-1 ${showPage === 4 ? "bg-blue-400" : ""}`}
          >
            Kategoriler
          </div>
          <div
            onClick={() => setShowPage(5)}
            className={`p-1 ${showPage === 5 ? "bg-blue-400" : ""}`}
          >
            Öğrenciler
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex w-full h-auto">
        {showPage === 1 && <div className="flex-1 bg-yellow-50">Kurumlar</div>}
        {showPage === 2 && (
          <div className="flex-1 bg-yellow-50">
            <Teacher />
          </div>
        )}
        {showPage === 3 && (
          <div className="flex-1 bg-yellow-50">
            <Courses />
          </div>
        )}
        {showPage === 4 && (
          <div className="flex-1 bg-yellow-50">
            <Category />
          </div>
        )}
        {showPage === 5 && (
          <div className="flex-1 bg-yellow-50">
            <Students />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
