import { useEffect } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";

function Modal({ children, onClose, title }) {
	useEffect(() => {
		if (!onClose) {
			return;
		}
		const checkIfClickedOutside = (e) => {
			if (e.target.id === "modal-control" && typeof onClose !== "undefined") {
				onClose();
			}
		};
		document.addEventListener("click", checkIfClickedOutside);
		return () => {
			document.removeEventListener("click", checkIfClickedOutside);
		};
	}, [onClose]);
	return (
		<div className='modal-control' id='modal-control'>
			<div className='bg-white p-4 rounded-sm drop-shadow-2xl opacity-100 h-3/4 z-20 overflow-y-scroll'>
				<div className='flex justify-between gap-3'>
					<div className='font-semibold text-3xl m-4'>{title}</div>
					<div className='flex flex-col justify-center bg-red-200 cursor-pointer rounded-lg' onClick={onClose}>
						<XMarkIcon className=' h-5 w-5 bold mx-4 scale-150' />
					</div>
				</div>
				{children}
			</div>
		</div>
	);
}

export default Modal;
