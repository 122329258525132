import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import { Link, useNavigate, NavLink } from "react-router-dom";

const ProfilePictureUpload = ({ user }) => {
  const [file, setFile] = useState(null);
  const authCtx = useContext(AuthContext);
  const [imgUrl, setImgURl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.photoUrl) {
      console.log(`photoUrl`);
      console.log(user.photoUrl);
      setImgURl(user.photoUrl);
    }
  }, [user]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const deleteHandler = async () => {
    setIsUploading(true);
	setFile(null)
    setTimeout(() => {
      console.log("Fotoğraf başarıyla silindi!");
      setIsUploading(false);
    }, 3000);
    const urlObject = new URL(imgUrl);
    const key = urlObject.pathname.substring(1);
    console.log(`key`);
    console.log(key);
    const data = {
      key: key,
    };

    fetch(`${process.env.REACT_APP_API_URL}/users/deleteFoto`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `${authCtx.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => console.log(response.json()))
      .then((response) => {
        console.log("Success:", response);
        setImgURl(null);
      })
      .catch((error) => {
        console.error("ErrorBurdan:", error);
      });
  };

  const handleUpload = async () => {
    setIsUploading(true);
    setTimeout(() => {
      console.log("Fotoğraf başarıyla yüklendi!");
      setIsUploading(false);
    }, 3000);
    const formData = new FormData();
    formData.append("photo", file);
    const config = {
      headers: {
        Authorization: `${authCtx.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/uploadFoto`,
        formData,
        config
      );
      console.log(response.data.url);
      setImgURl(response.data.url);
    } catch (error) {
      console.error("Error uploading photo:", error.message);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        {imgUrl ? (
          <div className="relative">
            <button
              onClick={deleteHandler}
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-2"
            >
              X
            </button>
            <img
              src={imgUrl}
              alt="Uploaded"
              className="w-40 h-40 object-cover rounded-full shadow-md"
            />
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
            />
            <label
              htmlFor="fileInput"
              className={`text-white bg-blue-500 hover:bg-blue-600 cursor-pointer m-2 p-2 rounded-lg transition-all duration-300 ease-in-out ${
                file ? "bg-red-300 hover:bg-red-400" : ""
              }`}
            >
              Dosya Seç
            </label>
            {file && (
              <div className="mt-2 text-sm">Seçilen Dosya: {file.name}</div>
            )}
            {file && !isUploading && (
              <button
                onClick={handleUpload}
                className="bg-green-500 hover:bg-green-600 text-white text-lg p-2 m-2 rounded-lg transition-all duration-300 ease-in-out"
              >
                Fotoğraf Yükle
              </button>
            )}
            {isUploading && (
              <div className="w-full max-w-xs">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                        Yükleniyor...
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                    <div
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProfilePictureUpload;
