import React, { useEffect, useState } from "react";

const Test = ({ chunkItem }) => {
	const [allText, setAlText] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
	const [showAnswer, setShowAnswer] = useState(false);

	const handleOptionSelect = (option) => {
		setSelectedOption(option);
		setIsAnswerCorrect(option === allText.correctAnswer);
	};

	useEffect(() => {
		if (chunkItem.testContent !== null) {
			getTests(chunkItem.testContent.link);
		}
	}, []);

	const getTests = async (link) => {
		const response = await fetch(link);
		const data = await response.json();
		setAlText(JSON.parse(data));
	};
	return (
		<>
			<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 ml-20'>
				{allText.length !== 0 && (
					<div className='max-w-md mx-auto my-8 p-4 bg-white shadow-md rounded-md w-96'>
						<h2 className='text-xl font-bold mb-4'>{allText.question} ?</h2>
						<div className='grid gap-4'>
							{allText.answers.map((option, index) => (
								<label key={index} className='flex items-center'>
									<input
										type='radio'
										value={option}
										checked={selectedOption === option}
										onChange={() => handleOptionSelect(option)}
										className='mr-2 cursor-pointer'
									/>
									<span>{option}</span>
								</label>
							))}
							<button className='p-3 m-2 bg-green-300 rounded-md w-32' onClick={() => setShowAnswer(!showAnswer)}>
								Kontrol et
							</button>
						</div>
						{selectedOption && (
							<p className={`mt-4 ${isAnswerCorrect ? "text-green-500" : "text-red-500"}`}>
								{showAnswer &&
									(isAnswerCorrect ? "Doğru cevap!" : "Yanlış cevap! Doğru cevap: " + allText.correctAnswer)}
							</p>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default Test;
