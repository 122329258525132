import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import { useHttp } from "../../hooks/useHttp";
import EksAddKavramModal from "./EksAddKavramModal";
import EksAddContentModal from "./EksAddContentModal";

const EksDetail = () => {
	const [course, setCourse] = useState({});
	const [openUnits, setOpenUnits] = useState([]);
	const [openKavramMpdal, setOpenKavramModal] = useState(false);
	const [openContentMpdal, setOpenContentModal] = useState(false);
	const [formContentData, setFormContentData] = useState({
		courseUniqId: "",
		courseName: "",
		sectionId: "",
		sectionName: "",
		chunkId: "",
		chunkName: "",
		link: "",
	});
	const { data, error, loading, sendRequest } = useHttp();

	const params = useParams();
	const { id } = params;

	useEffect(() => {
		getCourse();
	}, [id]);

	const getCourse = async () => {
		const responseCourse = await fetch(`${process.env.REACT_APP_API_URL}/courses/${id}`);
		const dataCourse = await responseCourse.json();
		console.log(`this course`);
		console.log(dataCourse);
		setCourse(dataCourse);
		setFormContentData({ ...formContentData, ["courseUniqId"]: dataCourse.uniqId, ["courseName"]: dataCourse.name });
		if (dataCourse.section) {
			setOpenUnits(dataCourse.section);
		}
	};

	const toggleUnit = (index) => {
		setOpenUnits((prev) => ({ ...prev, [index]: !prev[index] }));
	};
	const handleOpenKavramModal = () => {
		setOpenKavramModal(true);
		document.body.classList.add("overflow-hidden");
	};

	const handleOpenContentModal = (section = null, chunk = null) => {
		if (section && chunk) {
			console.log("section ve chunk değişti");
			console.log(section);
			console.log(chunk);
			setFormContentData({
				...formContentData,
				["sectionId"]: section.id,
				["sectionName"]: section.name,
				["chunkId"]: chunk.id,
				["chunkName"]: chunk.name,
			});
		} else if (section) {
			console.log("section değişti");
			console.log(section);
			setFormContentData({
				...formContentData,
				["sectionId"]: section.id,
				["sectionName"]: section.name,
			});
		} 
		console.log("açılıyor")
		setOpenContentModal(true);
		document.body.classList.add("overflow-hidden");
	};
	const hadleCloseModal = () => {
		console.log("e");
		setOpenKavramModal(false);
		setOpenContentModal(false);
		setFormContentData({
			courseUniqId: formContentData.courseUniqId,
			courseName: formContentData.courseName,
			sectionId: "",
			sectionName: "",
			chunkId: "",
			chunkName: "",
			link: "",
		});
		document.body.classList.remove("overflow-hidden");
	};
	const submitAddContent = (e) => {
		e.preventDefault();
		console.log(formContentData);
		sendRequest("/contribution","POST",formContentData);
	};

	useEffect(() => {
		if (data != null && data.success == true) {
			console.log(data);
		}
	}, [data]);

	


	return (
		<>
			{/* {openKavramMpdal && <EksAddKavramModal hadleCloseModal={hadleCloseModal} />} */}
			{openContentMpdal && (
				<EksAddContentModal
					handleCloseModal={hadleCloseModal}
					formData={formContentData}
					setFormData={setFormContentData}
					submitModal={submitAddContent}
				/>
			)}

			{course != null && course.sections ? (
				<>
					<div className='container mx-auto p-4'>
						{/* <div className='text-red-500 text-xl'>Kırmızı rakamları takip ederek eğitimi inceleyebilirsin.</div> */}
						{/* İlk Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<h2 className='text-2xl font-bold mb-2'>{course.name}</h2>
									<p>{course.description}</p>
								</div>
							</div>
							<div className='md:w-1/2 p-2'>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									{/* <div className='flex flex-col right-0'>
										<div className=' text-green-500 text-3xl p-2 flex justify-center'>
											{" "}
											TL {course.price !== null ? course.price : "Fiyat yok"}{" "}
										</div>
										
									</div> */}
								</div>
							</div>
						</div>
						{/* Üçüncü Row */}
						<div className='flex flex-col'>
							<div className='bg-white shadow-lg rounded-lg p-4'>
								<div className='flex ml-2 sm:ml-10 mb-4 font-semibold text-xl sm:text-2xl md:text-3xl'>
									<span>İçindekiler</span>
									<span className='button-contribution text-sm' onClick={() => handleOpenContentModal()}>
										Katkı Sağla
									</span>
								</div>
								{course.sections.map((section, index) => (
									<div key={index} className='p-2 mb-4 w-fit'>
										<div>
											<h4
												onClick={() => toggleUnit(index)}
												className='text-lg font-semibold cursor-pointer bg-gray-200 p-4 rounded-xl w-full sm:min-w-[300px] sm:w-auto'
											>
												Ünite{" "}
												<span>
													{section.orderNo}: {section.name}
												</span>
											</h4>
										</div>
										{openUnits[index] && (
											<div>
												<div className='flex m-2 overflow-hidden'>
													<span className='button-contribution text-sm' onClick={() => handleOpenContentModal(section)}>
														Katkı Sağla
													</span>
													{/* <span className='w-fit sm:min-w-[100px] flex justify-center bg-red-200 px-1 text-sm rounded-xl '>
														Kazanım
													</span> */}
													<span className='flex justify-center mx-2'>
														{/* Assuming ArrowLongRightIcon is a component */}
														<ArrowLongRightIcon className='w-4 h-4' />
													</span>
													<span className='pl-3'> {section.description}</span>
												</div>
												<div className='pl-2 sm:pl-5'>
													{section.chunks.map((chunk, idx) => (
														<div
															key={idx}
															className='flex flex-col sm:flex-row my-3 border border-gray-200 rounded-lg p-2 min-w-[100px] overflow-hidden'
														>
															<div className='flex flex-col sm:flex-row gap-2 text-xl  sm:text-base'>
																<div className='w-full sm:min-w-[100px] '>
																	{section.orderNo}.{chunk.orderNo}. {chunk.name}
																</div>
																<div className='flex flex-row '>
																	<span
																		className='button-contribution text-sm'
																		onClick={() => handleOpenContentModal(section, chunk)}
																	>
																		Katkı Sağla
																	</span>
																	{/* <span className='flex justify-center items-center sm:min-w-[100px] bg-red-200 px-1 text-sm rounded-xl'>
																		Kazanım
																	</span> */}
																	<span className='flex justify-center items-center mx-2'>
																		{/* Assuming ArrowLongRightIcon is a component */}
																		<ArrowLongRightIcon className='w-4 h-4' />
																	</span>
																	<span className='w-full sm:min-w-[100px] text-sm flex justify-center items-center'>
																		{chunk.description}
																	</span>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				</>
			) : (
				<div>Course pending</div>
			)}
		</>
	);
};

export default EksDetail;
