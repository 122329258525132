import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useHttp } from "../../hooks/useHttp";
import { Link, useNavigate, NavLink } from "react-router-dom";

const Payment = () => {
	const authCtx = useContext(AuthContext);
	const [userBaskets, setUserBaskets] = useState([]);
	const { data, error, loading, sendRequest } = useHttp();
	const navigate = useNavigate();

	useEffect(() => {
		getUserBasket();
	}, []);

	const getUserBasket = async () => {
		// const data2 = { paid:true };
		if (authCtx.token !== null) {
			sendRequest("/userCoursePayments");
		}
	};

	useEffect(() => {
		if (data != null && userBaskets.length == 0) {
			setUserBaskets(data.userCoursePayments);
			console.log(data);
			console.log("girdi DATA1");
		}
	}, [data]);

	const handlePayment = (uniqId) => {
		const dataPayment = { courseUniqId: uniqId };
		console.log("pay");
		console.log(dataPayment);
		sendRequest(`/userCoursePayments/${uniqId}`, "PUT");
	};

	return (
		<div>
			<div className='flex flex-col items-center gap-3 px-2 sm:px-0'>
				<div className='text-xl sm:text-2xl'>Sepet</div>
				{userBaskets.length != 0 &&
					userBaskets.map((item) => (
						<div className='flex flex-col w-full border border-red-400'>
							<div className='flex flex-col sm:flex-row justify-around items-center py-2'>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.name}
								</div>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.price} TL
								</div>
								<div className='flex flex-col justify-center text-lg sm:text-2xl font-semibold my-1'>
									{item.basketCourse.createdAt}
								</div>
								<div className='flex flex-col justify-center items-center'>
									{item.paid == true ? (
										<div className='flex flex-row items-center gap-3'>
											<div className='button-warn text-sm sm:text-base'>Ödendi</div>
											<div
												className='bg-blue-400 p-2 sm:p-3 rounded-md m-2 cursor-pointer text-sm sm:text-base'
												onClick={() => {
													navigate("/profile");
												}}
											>
												Kursa Git
											</div>
										</div>
									) : (
										<div
											className='button-green text-sm sm:text-base'
											onClick={() => handlePayment(item.basketCourse.uniqId)}
										>
											Öde
										</div>
									)}
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Payment;
