import React, { useEffect, useState, useContext } from "react";
import Video from "./Video";
import Kelime from "./Kelime";
import Test from "./Test";
import Reading from "./Reading";
import { useParams } from "react-router-dom";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const CourseStart = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [showFinish, setShowFinish] = useState(false);
	const [showFinishSaved, setShowFinishSaved] = useState(false);
	const [allData, setAllData] = useState({});
	const [chunkAllItems, setChunkAllItems] = useState([]);
	const params = useParams();
	const authCtx = useContext(AuthContext);
	const { id } = params;
	const navigate = useNavigate();

	useEffect(() => {
		console.log(`id`);
		console.log(id);
		getLastChunkItems();
	}, [authCtx]);

	const getLastChunkItems = async () => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/userCourseProgresses/${id}/start`, {
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
		});
		const data = await response.json();
		console.log("chunkItems");
		console.log(data);
		setAllData(data);
		setChunkAllItems(data.courseUserCourse.sections[0].chunks[0].chunkItems);
	};

	const handlePlusSayfa = () => {
		if (currentPage + 1 < chunkAllItems.length) {
			setCurrentPage((prevPage) => prevPage + 1);
		} else {
			setShowFinish(true);
		}
	};
	const handleMinusSayfa = () => {
		if (currentPage !== 0) {
			setCurrentPage((prevPage) => prevPage - 1);
			setShowFinish(false);
		}
	};

	const handleSaveChunk = () => {
		fetch(`${process.env.REACT_APP_API_URL}/userCourseProgresses/${allData.courseId}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setShowFinishSaved(true);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	return (
		<>
			{chunkAllItems.length !== 0 ? (
				<div>
					<div>
						<div className='flex flex-row justify-end'>
							<button onClick={handleMinusSayfa}>
								<ArrowLongLeftIcon className='w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer' />
							</button>

							<div className='flex self-center text-2xl '>
								{currentPage + 1}/{chunkAllItems.length}
							</div>

							<button onClick={handlePlusSayfa}>
								<ArrowLongRightIcon className='w-10 h-10 border rounded-3xl bg-green-500 cursor-pointer' />
							</button>
						</div>

						<div>
							<div className='flex flex-row justify-between my-5'>
								<div className='flex'>
									<div className='font-bold text-lg border rounded-3xl bg-red-600 self-center'>Stop</div>

									<div>
										<ClockIcon className='w-6 h-6' />
									</div>

									<div>15:00</div>
								</div>
								<div className='flex'>
									<ClockIcon className='w-6 h-6' />
									<div className='pe-16'>60:00</div>
								</div>
							</div>
						</div>
					</div>
					{showFinish && showFinishSaved ? (
						<div className='flex flex-row justify-center'>
							<div className='flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300'>
								<div className='m-4'>Tebrikler Tamamladınız.</div>
								<button className='p-3 bg-green-300' onClick={() => navigate("/profile")}>
									Profil sayfasına git
								</button>
							</div>
						</div>
					) : showFinish ? (
						<div className='flex flex-row justify-center'>
							<div className='flex flex-col justify-center w-96 h-32 p-3 m-2 bg-red-300'>
								<div className='m-4'>Kursu tamladınız. Lütfen kayıt edin.</div>
								<button className='p-3 bg-green-300' onClick={handleSaveChunk}>
									Kaydet
								</button>
							</div>
						</div>
					) : (
						<div className='mt-10'>
							{chunkAllItems[currentPage].type == "video" && <Video item={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "test" && <Test chunkItem={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "reading" && <Reading chunkItem={chunkAllItems[currentPage]} />}
							{chunkAllItems[currentPage].type == "word" && <Kelime chunkItem={chunkAllItems[currentPage]} />}
						</div>
					)}
				</div>
			) : (
				<div>Loading</div>
			)}
		</>
	);
};

export default CourseStart;
