import React, { useEffect, useState, useContext } from "react";
import VideoContent from "./VideoContent";
import ReadingContent from "./ReadingContent";
import SummaryContent from "./SummaryContent";
import WordContent from "./WordContent";
import TestContent from "./TestContent";

const CourseContentChunkItem = ({ chunkItems }) => {
	// const [chunkItemss, setChunkItemss] = useState(null);
	const [showPage, setShowPage] = useState(0);

	useEffect(() => {
		console.log(`chunkItems`);
		console.log(chunkItems);
		// setChunkItemss(chunkItems);
	}, [chunkItems]);

	const handlePage = (sayi) => {
		console.log(sayi);
		if (sayi == 1) {
			if (showPage +2 <= chunkItems.length) {
				setShowPage((prevState) => prevState + 1);
			} else {
				setShowPage(0);
			}
		} else if (sayi == -1) {
			if (showPage !== 0) {
				setShowPage((prevState) => prevState - 1);
			} else {
				setShowPage(chunkItems.length - 1);
			}
		}
	};

	return (
		<>
			<div>
				<div className='flex flex-row'>
					{chunkItems.length !== 0 && chunkItems !== null ? (
						chunkItems[showPage].type == "video" ? (
							<div className='flex flex-col justify-center'>
								<VideoContent chunkItem={chunkItems[showPage]} />
							</div>
						) : chunkItems[showPage].type == "test" ? (
							<div className='flex flex-col justify-center'>
								<TestContent chunkItem={chunkItems[showPage]} />
							</div>
						) : chunkItems[showPage].type == "reading" ? (
							<div className='flex flex-col justify-center'>
								<ReadingContent chunkItem={chunkItems[showPage]} />
							</div>
						) : chunkItems[showPage].type == "word" ? (
							<div className='flex flex-col justify-center'>
								<WordContent chunkItem={chunkItems[showPage]} />
							</div>
						) : chunkItems[showPage].type == "summary" ? (
							<div className='flex flex-col justify-center'>
								<SummaryContent chunkItem={chunkItems[showPage]} />
							</div>
						) : (
							<div>There is no type</div>
						)
					) : (
						<div> Chunk Item yok</div>
					)}
				</div>

				<div className='w-22 flex flex-row justify-between mt-10 m-2'>
					<button
						className='bg-green-200 p-2 w-32 rounded-md'
						onClick={() => {
							handlePage(-1);
						}}
					>
						Önceki Sayfa
					</button>
					<button
						className='bg-green-200 p-2 w-32 rounded-md'
						onClick={() => {
							handlePage(1);
						}}
					>
						Sonraki Sayfa
					</button>
				</div>
			</div>
		</>
	);
};

export default CourseContentChunkItem;
