import React from "react";
import Main from "../components/MainPage/Main";

const MainPage = () => {
	return (
		<div>
			<Main />
		</div>
	);
};

export default MainPage;
