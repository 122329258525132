import React from "react";
import CoursePage from "../../pages/CoursePage";
import LoggedInUnpayed from "./LoggedInUnpayed";
import LoggedInPayed from "./LoggedInPayed";
import MainDesc from "./MainDesc";

const Main = () => {
	const data = [
		{
			title: "Zincir Okul",
			description: "Bir online güncelleme alan offline eğitim yeridir.",
			items: ["Yazılım Mühendisi Cem Gökçe tarafından yapılmıştır."],
			backGroundColor: "bg-red-500",
		},
		{
			title: "Eğitim",
			description: "Satın aldığınız eğitim öğrenmek amaçlıdır.",
			items: [
				"Yani sınava hazırlanmak veya ezber yapmak için değildir.",
				"Hayat boyu kullanmak için öğretiyoruz.",
				"Aldığınız eğitimin bizim açımızdan bir amacı olmalıdır.",
				"Günümüzde bir çok online eğitim satınmaktadır amaçları belirsizdir.",
			],
			backGroundColor: "bg-red-500",
		},
		{
			title: "Paydaşlar",
			description: "Öğretmen, Eğitim ve Öğrenci",
			items: [
				"Sonradan yetişecek mentör öğreciler.",
				"Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar etmiş olup kalıcı öğrenmeyi gerçekleştirir.",
				
			],
			backGroundColor: "bg-red-400",
		},
		{
			title: "İlkemiz",
			description: "Öğrenmeye odaklan.",
			items: [
				"Kaynak arama öğremenlerimiz senin için en iyi kaynakları bulur.",
				"Öğrenci olarak görevin kaynak aramak değil ders çalışmak.",
				"Zincir okul olarak biz bu görevi öğretmenlerimeze veriyoruz ve kaynaklarını güncel tutmaları gerektiği",
				"Tamam dersi öğrendin ama hayat değişiyor baştan mı öğreniceksin hayır tabikide şimdi güncellenen kaynakalrı gözden geçir neler değişmiş hayatta.",
				"Güncellenen kaynakları gözden geçir.",
				// "müdendis cem zincir okul projesi ile eğitim sistemine yeni bir bakış açısı getirmiştir.",
			],
			backGroundColor: "bg-red-400",
		},

		// Diğer kutuların verileri...
	];

	const Box = ({ title, description, items, backGroundColor }) => (
		<>
			<div className={`flex flex-col justify-center rounded  h-auto min-h-96  text-white md:min-w-[300px] ${backGroundColor ? backGroundColor : ""}`}>
				<div className='flex flex-col justify-center mx-5'>
					<div className='text-2xl'>{title}</div>
					<div className='text-xl mb-2'>{description}</div>
					<div className=' pl-10'>
						<ul className='list-disc tex-small'>
							{items.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	);



	return (
		<>
			<div className='flex flex-col'>
				<div className='bg-red-500 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl my-2'>
					<div className='flex flex-col justify-center'>Tanıtım</div>
				</div>
				<div className='container mx-auto p-4'>
					<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
						{data.map((item, index) => (
							<Box key={index} {...item} />
						))}
					</div>
				</div>
				{/* <MainDesc /> */}
				<div className=''>
					<CoursePage />
				</div>
			</div>
		</>
	);
};

export default Main;
