import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../store/auth-context";

const Footer = () => {
	return (
			<footer className='bg-red-500 text-white h-full w-full flex flex-col md:flex-row justify-around gap-2'>
				<div className='flex flex-col p-1'>
					
					<div className="font-bold">@ 2023 Zincir Okul</div>
					<div>Yazılım Mühendisi Cem GÖKÇE</div>
				</div>
				<div className='flex flex-col p-1'>
					<div className="font-bold">Sözleşmeler</div>
					<div>Gizlilik Sözleşmesi</div>
					<div>Mesafeli Satış Sözleşmesi</div>
					<div>İptal/iade Sözleşmesi</div>
				</div>
				<div className='flex flex-col p-1'>
					<div className="font-bold">Hakkımızda</div>
					<div className="font-bold">Site Haritası</div>
				</div>
				{/* <div className='flex flex-col w-96 p-1'> */}
					{/* <div>Alanınızda çok mu iyisiniz ?</div>
					<div>Çok çok mu ?</div>
					<div>Disiplin göbek adınız mı ?</div> */}
					{/* <div className='bg-red-300'>
					Bide herhangi bir değişiklikte kursunuzu güncelliceksiniz milleti araştırma yapmaktan kurtarcaksınız onlar sadece
					derslerine çalışacak bir şey düşünmeyecek öyle yaniyse
				</div> */}
					{/* <div>Zincir okulda öğretmen olmak istermisiniz ?</div> */}
					{/* <Link to='teacher-application' className='cursor-pointer bg-green-300 p-2 m-1 rounded-lg'>
						Öğretmen olma formunu doldurun.
					</Link> */}
				{/* </div> */}
			</footer>		
	);
};

export default Footer;
