import { useState, useContext, useCallback } from "react";
import AuthContext from "../store/auth-context";

export const useHttp = () => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const authCtx = useContext(AuthContext);

	const sendRequest = useCallback(async (url, method = "GET", body = null, headers = {}) => {
		setLoading(true);
		setError(null);
		try {
			const requestOptions = {
				method,
				headers: { "Content-Type": "application/json", Authorization: ` ${authCtx.token}`, ...headers },
				body: body ? JSON.stringify(body) : null,
			};
			const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			} else {
				console.log(`ok`);
			}
			const responseData = await response.json();
			console.log(responseData)
			setData(responseData);
		} catch (e) {
			setError(e.message);
		} finally {
			setLoading(false);
		}
	}, []);

	return { data, error, loading, sendRequest };
};
