import React, { Fragment, useState, useEffect } from "react";
import Course from "../components/Course/Course";
import { useParams } from "react-router-dom";

function CoursesByCategoryId() {
	const params = useParams();
	const { id } = params;
	console.log(`id`);
	console.log(id);
	const [courses, setCourses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getCourse = async () => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/courses/${id}/category`);
		const data = await response.json();
		setCourses(data);
		console.log(data);
		setIsLoading(false);
	};

	useEffect(() => {
		getCourse();
	}, [id]);

	return (
		<Fragment>
			{isLoading ? (
				<p>Loading</p>
			) : (
				<div className='bootstrap container'>
					<div className='bg-red-500 text-white rounded-xl w-full h-20 flex flex-row justify-center text-3xl m-4'>
						<div className='flex flex-col justify-center'>Kurslar</div>
					</div>
					<div className='card-list'>
						{courses.length == 0 ? (
							<div className='flex flex-row justify-center text-3xl'>{`"${id}" id kelimesi ile ilgili kurs bulunamadi.`}</div>
						) : (
							courses.map((course) => (
								<Course key={course.id} id={course.id} name={course.name} description={course.description} />
							))
						)}
						{}
					</div>
				</div>
			)}
		</Fragment>
	);
}

export default CoursesByCategoryId;
