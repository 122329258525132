import React, { useState, useEffect } from "react";
import CourseContentChunkItem from "./CourseContentChunkItem";
import Dropdownlist from "../../UI/Dropdownlist";

const CourseContent = ({ courseId }) => {
	const [course, setCourse] = useState([]);
	const [chunks, setChunks] = useState([]);
	const [chunkItems, setChunkItems] = useState([]);
	const [selectedChunkItem, setSelectedChunkItem] = useState(0);

	useEffect(() => {
		getCourseWithParts();
	}, []);

	const getCourseWithParts = async () => {
		if (courseId !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/courses/${courseId}`);
			const data = await response.json();
			console.log("course");
			console.log(data);
			setCourse(data);
		}
	};

	const handleChunks = (e) => {
		if (e.target.value !== 0) {
			let sectionone = course.sections.find((item) => item.id == e.target.value);
			console.log(`sectionone`);
			console.log(sectionone);
			if (sectionone.chunks.length !== 0 && sectionone.chunks !== null && sectionone !== undefined) {
				console.log(`section degisti`);
				console.log(sectionone.chunks)
				setChunks(sectionone.chunks);
				setChunkItems([]);
				setSelectedChunkItem(0);
			} else {
				setChunks([]);
			}
		} else {
			setChunks([]);
			setChunkItems([]);
		}
	};

	const handleChunkItems = (e) => {
		if (e.target.value !== 0) {
			console.log(e.target.value);
			let chunkOne = chunks.find((item) => item.id == e.target.value);
			console.log(`chunkOne`);
			console.log(chunkOne);
			setSelectedChunkItem(chunkOne.id);
			if (chunkOne !== undefined && chunkOne.chunkItems.length !== 0 && chunkOne.chunkItems !== null) {
				setChunkItems(chunkOne.chunkItems);
			} else {
				setChunkItems([]);
			}
		} else {
			setChunkItems([]);
		}
	};

	return (
		<>
			{course.length !== 0 ? (
				<div>
					<div className='flex flex-row justify-start pl-20 gap-4 mt-5 bg-blue-300 p-2'>
						<div className='flex flex-row gap-1 '>
							<select className='p-1' onChange={handleChunks}>
								<option value='0'>Ünite Seçiniz</option>
								{course.sections.map((item) => (
									<option value={item.id}>
										{item.orderNo}- {item.name}
									</option>
								))}
							</select>
						</div>
						{chunks !== null && chunks.length !== 0 && (
							<Dropdownlist
								content={chunks.map((item) => (
									<option value={item.id}>
										{item.orderNo}- {item.name}
									</option>
								))}
								title='Konu seçiniz'
								handleSelected={handleChunkItems}
								selectedItem={selectedChunkItem}
							/>
						)}
					</div>
					{chunkItems.length !== 0 && chunkItems.length !== null ? (
						<div>
							<CourseContentChunkItem chunkItems={chunkItems} />
						</div>
					) : (
						<div className='w-full h-full text-3xl text-red-400 flex flex-row justify-center mt-20'>Icerik yok.</div>
					)}
				</div>
			) : (
				<div>Herhangi bir ünite ve konu tanımlanmamıştır. Lutfen kurs yapısı tanımlayınız.</div>
			)}
		</>
	);
};

export default CourseContent;
