import React, { useState, useEffect } from "react";
import { useHttp } from "../../hooks/useHttp";

const Category = () => {
  // State to manage category list and new category
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const { data, error, loading, sendRequest } = useHttp();

  // Fetch categories from backend on component mount
  useEffect(() => {
    sendRequest(`/categories`, "GET");
  }, []);

  useEffect(() => {
    console.log("data category");
    console.log(data);
    if (Array.isArray(data)) {
      setCategories(data);
    } else {
        sendRequest(`/categories`, "GET");
    }
  }, [data]);

  // Function to handle new category form submission
  const handleAddCategory = (e) => {
    e.preventDefault();
    sendRequest(`/categories`, "POST", { name: newCategory });
    setNewCategory("")
  };

  return (
    <div>
      <h2>Categories</h2>

      {/* Display the list of categories */}
      <ul>
        {categories.map((category) => (
          <li
            key={category.id}
            className="bg-red-300 border-1 border-black w-fit p-2 rounded-xl m-3"
          >
            {category.name}
          </li>
        ))}
      </ul>

      {/* Form to add a new category */}
      <form onSubmit={handleAddCategory}>
        <label>
          New Category:
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            required
          />
        </label>
        <button type="submit">Add Category</button>
      </form>
    </div>
  );
};

export default Category;
