import { useState, useRef, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";

import classes from "./AuthForm.module.css";

const AuthForm = () => {
	const history = useNavigate();

	const emailInputRef = useRef();
	const passwordInputRef = useRef();
	const firstNameInputRef = useRef();
	const lastNameInputRef = useRef();
	// const [radioTeacher, setRadioTeacher] = useState(false);

	const authCtx = useContext(AuthContext);

	const [isLogin, setIsLogin] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const switchAuthModelHandler = () => {
		setIsLogin((prevState) => !prevState);
	};
	// const radioHandler = () => {
	// 	setRadioTeacher(!radioTeacher);
	// 	console.log(radioTeacher);
	// };
	const submitHandler = (event) => {
		event.preventDefault();

		const enteredEmail = emailInputRef.current.value;
		const enteredPassword = passwordInputRef.current.value;
		let enteredFirstName;
		let enteredLastName;
		let enteredroleStatus;
		if (!isLogin) {
			enteredFirstName = firstNameInputRef.current.value;
			enteredLastName = lastNameInputRef.current.value;
			// enteredroleStatus = radioTeacher;
		}

		setIsLoading(true);
		let url;

		if (isLogin) {
			url = `${process.env.REACT_APP_API_URL}/auth/login`;
			fetch(url, {
				method: "POST",
				body: JSON.stringify({
					email: enteredEmail,
					password: enteredPassword,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => {
					setIsLoading(false);
					if (res.ok) {
						return res.json();
					} else {
						return res.json().then((data) => {
							//show an eror modal
							// console.log(data)
							let errorMessage = "Authentication Failed";
							// if (data && data.error && data.error.message) {
							//   errorMessage = data.error.message;
							// }
							throw new Error(errorMessage);
						});
					}
				})
				.then((data) => {
					console.log(data.token);
					authCtx.login(data.token);
					history("/");
				})
				.catch((err) => {
					alert(err.message);
				});
		} else {
			// SIGN UP - ÜYE OL
			url = `${process.env.REACT_APP_API_URL}/auth/register`;
			fetch(url, {
				method: "POST",
				body: JSON.stringify({
					email: enteredEmail,
					password: enteredPassword,
					firstName: enteredFirstName,
					lastName: enteredLastName,
					rolestatus: enteredroleStatus,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then((res) => {
					setIsLoading(false);
					if (res.ok) {
						console.log(res.json());
						return res;
					} else {
						return res.json().then((data) => {
							let errorMessage = "Authentication Failed";

							throw new Error(errorMessage);
						});
					}
				})
				.then((data) => {
					history("/auth");

					setIsLogin(true);
				})
				.catch((err) => {
					alert(err.message);
				});
		}
	};

	return (
		<section className={`${classes.auth} bg-red-500`}>
			<h1>{isLogin ? "Giriş yap" : "Kayıt ol"}</h1>
			<form onSubmit={submitHandler}>
				{!isLogin ? (
					<div>
						<div className={classes.control}>
							<label htmlFor='firstName'>Ad</label>
							<input type='firstName' id='firstName' required ref={firstNameInputRef} />
						</div>
						<div className={classes.control}>
							<label htmlFor='lastName'>Soyad</label>
							<input type='lastName' id='lastName' required ref={lastNameInputRef} />
						</div>
					</div>
				) : null}
				<div className={classes.control}>
					<label htmlFor='email'>Email</label>
					<input type='email' id='email' required ref={emailInputRef} />
				</div>
				<div className={classes.control}>
					<label htmlFor='password'>Sifreniz</label>
					<input type='password' id='password' required ref={passwordInputRef} />
				</div>
				{/* {!isLogin ? (
					<div className={classes.controlCheckbox}>
						<label className={{ display: "none" }} htmlFor='radio'>
							Ogretmen olmak ister misin :
						</label>
						<input type='checkbox' checked={radioTeacher} onChange={radioHandler} />
					</div>
				) : null} */}
				<div className={classes.actions}>
					{!isLoading && <button>{isLogin ? "Giris Yap" : "Kayit Ol"}</button>}
					{isLoading && <p>Sending Request... </p>}
					<button type='button' className={`${classes.toggle} bg-green-500`} onClick={switchAuthModelHandler}>
						{isLogin ? "Kayit Ol" : "Giris Yap"}
					</button>
				</div>
				<div>
					{isLogin ? (
						<button type='button' className='bg-gray-500 p-2 mt-5' onClick={()=>{history("/forgot-password")}}>
							Şifremi unuttum
						</button>
					) : (
						<></>
					)}
				</div>
			</form>
		</section>
	);
};
export default AuthForm;
