import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../store/auth-context";
import CourseDetail from "../components/Course/CourseDetail";

const CourseDetailPage = () => {
	const authCtx = useContext(AuthContext);
	const [course, setCourse] = useState(null);
	const [mine, setMine] = useState(false);

	const params = useParams();
	const { id } = params;

	useEffect(() => {
		console.log(authCtx);
		getCourse();
	}, []);

	const getCourse = async () => {
		const responseCourse = await fetch(`${process.env.REACT_APP_API_URL}/courses/${id}`);
		const dataCourse = await responseCourse.json();
		console.log(`this course`);
		console.log(dataCourse);
		setCourse(dataCourse);
		// setIsLoading(false);

		const responseUserCourses = await fetch(`${process.env.REACT_APP_API_URL}/users/single`, {
			method: "GET",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
		});
		const dataMyCourses = await responseUserCourses.json();
		if (dataMyCourses !== null) {
			console.log(`my courses`);
			console.log(dataMyCourses);
			let benimmi = dataMyCourses.courses.find((item) => item.course.id == dataCourse.id);
			console.log(`benimmi`);
			console.log(benimmi);
			if (benimmi !== undefined) {
				setMine(true);
			} else {
				setMine(false);
			}
		}
	};

	return <>{course !== null ? <CourseDetail course={course} mine={mine} /> : <div>Course pending</div>}</>;
};

export default CourseDetailPage;
