import React, { useState } from "react";
import Modal from "../UI/Modal";
const EksAddContentModal = ({ handleCloseModal, submitModal, formData, setFormData }) => {
	const handleChange = (e) => {
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		setFormData({ ...formData, [e.target.name]: value });
	};

	// const submitle = (e) => {
	// 	e.preventDefault();
	// 	console.log(formData);
	// };

	return (
		<Modal onClose={handleCloseModal} title='İçerik Katkı'>
			<div className=''>
				<form onSubmit={submitModal} className='space-y-4 mb-10'>
					<div>
						<label htmlFor='courseName' className='block text-sm font-medium text-gray-700'>
							Kurs Adı
						</label>
						<input
							type='text'
							name='courseName'
							id='courseName'
							value={formData.courseName}
							disabled
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					{formData.sectionName != "" && (
						<div>
							<label htmlFor='sectionName' className='block text-sm font-medium text-gray-700'>
								Ünite Adı
							</label>
							<input
								type='text'
								name='sectionName'
								id='sectionName'
								value={formData.sectionName}
								disabled
								required
								className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
								onChange={handleChange}
							/>
						</div>
					)}
					{formData.chunkName != "" && (
						<div>
							<label htmlFor='chunkName' className='block text-sm font-medium text-gray-700'>
								Konu Adı
							</label>
							<input
								type='text'
								name='chunkName'
								id='chunkName'
								disabled
								value={formData.chunkName}
								required
								className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
								onChange={handleChange}
							/>
						</div>
					)}
					<div className=' bg-green-200'>Google drive üzerinden link bırakabilirsin</div>
					<div>
						<label htmlFor='link' className='block text-sm font-medium text-gray-700'>
							link
						</label>
						<input
							type='text'
							name='link'
							id='link'
							value={formData.link}
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>

					<div className='flex justify-center'>
						<button
							type='submit'
							className='w-3/4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							Katkı Sağla
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default EksAddContentModal;
