import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ChunkAdd = ({ setNewItemShow, sectionId, chunk }) => {
	const [name, setName] = useState(null);
	const [desc, setDesc] = useState(null);
	const [orderNo, setOrderNo] = useState(null);

	const authCtx = useContext(AuthContext);

	const handleChunknName = (e) => {
		console.log(e.target.value);
		setName(e.target.value);
	};

	const handleChunkDesc = (e) => {
		console.log(e.target.value);
		setDesc(e.target.value);
	};

	const handleOrderNo = (e) => {
		console.log(e.target.value);
		setOrderNo(e.target.value);
	};

	//handle submit

	const handleSubmit = () => {
		const data = {
			name: name,
			description: desc,
			sectionId: sectionId,
			orderNo: orderNo,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/chunks`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	useEffect(() => {
		if (chunk !== null) {
			setName(chunk.name);
			setDesc(chunk.description);
			setOrderNo(chunk.orderNo);
		} else {
			console.log("girdi");
			setName("");
			setDesc("");
			setOrderNo("");
		}
	}, [chunk]);

	//
	const handleUpdate = () => {
		const data = {
			name: name,
			description: desc,
			sectionId: sectionId,
			orderNo: orderNo,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/chunks/${chunk.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	const handleDelete = () => {

		fetch(`${process.env.REACT_APP_API_URL}/chunks/${chunk.id}`, {
			method: "DELETE",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
		})
			.then((response) => {
				console.log("Success:", response);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};



	return (
		<>
			<div className='flex flex-row justify-center'>
				<div className='flex flex-col justify-center gap-4 p-4'>
					{/* <div className='flex flex-row justify-between'>
						{chunk !== null ? (
							<div className='text-2xl semibold '>{chunk.orderNo}. Konu Güncelleme</div>
						) : (
							<div className='text-2xl semibold '>Konu Ekleme</div>
						)}

						<div>
							<XMarkIcon
								className='h-5 w-5 scale-150'
								onClick={() => {
									setNewItemShow(false);
								}}
							/>
						</div>
					</div> */}

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Adi : </div> */}
						<input
							placeholder='Lutfen kurs adini giriniz'
							value={name}
							onChange={handleChunknName}
							className='p-2 w-72 border border-black'
						/>
					</div>

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Aciklamasi : </div> */}
						<textarea
							placeholder='Lutfen kurs aciklamasi giriniz'
							onChange={handleChunkDesc}
							value={desc}
							rows={5}
							className='p-2 h-32 w-72 border border-black'
						/>
					</div>
					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Sira no : </div> */}
						<input
							type='number'
							placeholder='Lutfen unite sira numarasini giriniz'
							value={orderNo}
							onChange={handleOrderNo}
							className='p-2 w-72 border border-black'
						/>
					</div>

					<div className='w-22 flex flex-row justify-center'>
						{chunk !== null ? (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleUpdate}>
								Güncelle
							</button>
						) : (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleSubmit}>
								Kayit Et
							</button>
						)}
						<button className='bg-red-200 p-2 m-2 w-32 rounded-md' onClick={handleDelete}>
								DELETE
							</button>
					</div>

				</div>
			</div>
		</>
	);
};

export default ChunkAdd;
