import React, { useState, useEffect, useContext } from "react";

const CourseTeacherDetail = ({ course }) => {
	const {
		name,
		photo,
		goals,
		forWho,
		active,
		description,
		totalCompleteTimeType,
		totalCompleteTimeNumber,
		courseTimeNumber,
		courseTimeType,
		chunkTimeNumber,
		chunkTimeType,
		categoryId,
		category,
	} = course;
	const [courseGoals, setGoals] = useState([]);
	const [courseForWho, setForWho] = useState([]);

	useEffect(() => {
		if (goals !== null) {
			setGoals(JSON.parse(goals));
		} else {
			setGoals([]);
		}
		if (forWho !== null) {
			setForWho(JSON.parse(forWho));
		} else {
			setGoals([]);
		}
	}, [course]);

	return (
		<div className='flex flex-col gap-2 m-3'>
			<div className='flex flex-row justify-center  w-full text-2xl font-bold my-2 '>
				<span className='flex-1 flex justify-end'>{name}</span>
				{/* <span className='flex-1 flex justify-end mr-10  text-sm'> {category.name}</span> */}
				{/* <span className='flex-1 flex text-sm'>{active == true ? "yainlandi":"hazirlaniyor"}</span> */}
			</div>

			<div className='flex mt-10 italic'>
				<span>{description}</span>
			</div>
			<div>
				<div className='text-xl text-blue-400'>Çalışma Şekli ve Sistem</div>
				<div>
					<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
						<span>{totalCompleteTimeNumber}</span> <span>{totalCompleteTimeType}</span> tamamlanacaktır.
					</div>
					<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
						Her <span>{courseTimeType}</span> <span>{courseTimeNumber}</span> kere calisilicakitr.
					</div>
					<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
						1 kerede <span>{chunkTimeNumber}</span> <span>{chunkTimeType}</span> calisilicaktir.
					</div>
				</div>
			</div>
			<div>
				<div className='text-xl text-blue-400'>Amaç</div>
				<div>
					{courseGoals.length !== 0 ? (
						<div>
							<ul className='mt-4 mb-4'>
								{courseGoals.map((goal, index) => (
									<div
										key={index}
										className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between font-bold'
									>
										<span>{goal.goal}</span>
									</div>
								))}
							</ul>
						</div>
					) : (
						<div>Amac tanimlanmamistir.</div>
					)}

					{/* <span>{totalCompleteTimeNumber}</span> <span>{totalCompleteTimeType}</span> tamamlanacaktır. */}
				</div>
			</div>
			<div>
				<div className='text-xl text-blue-400'>Kriter</div>
				<div>
					{courseForWho.length !== 0 ? (
						<div>
							<ul className='mt-4 mb-4'>
								{courseForWho.map((item, index) => (
									<div
										key={index}
										className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between font-bold'
									>
										<span>{item.forWho}</span>
									</div>
								))}
							</ul>
						</div>
					) : (
						<div>Amac tanimlanmamistir.</div>
					)}

					{/* <span>{totalCompleteTimeNumber}</span> <span>{totalCompleteTimeType}</span> tamamlanacaktır. */}
				</div>
			</div>
		</div>
	);
};

export default CourseTeacherDetail;
