import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ProfileCourses = ({ item }) => {
	useEffect(() => {
		console.log(`item`);
		console.log(item);
	}, []);

	return (
		<>
			<div>
				<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 m-6'>
					<div className='col-span-1 border-black border-solid border-2 pb-2'>
						<h2 className='p-2 text-lg sm:text-xl flex justify-center'>{item.course.name}</h2>
						<div className='flex flex-row justify-center items-center gap-2'>
							<span className='text-xs'>Level</span>
							<h2 className='bg-green-200 w-auto h-16 sm:w-10 sm:h-auto rounded-full py-4 sm:py-8 px-6 sm:px-12 flex justify-center text-3xl sm:text-4xl font-medium'>
								{item.progress.levelSection == 1 && item.progress.levelChunk == 0 ? (
									<span>0</span>
								) : (
									<span>
										{item.progress.levelSection}
										{item.progress.levelChunk}
									</span>
								)}
							</h2>
						</div>
					</div>
					<div className='col-span-3 border-black border-solid border-2'>
						<div className='flex flex-col sm:flex-row justify-evenly m-6'>
							<section className='mb-4 sm:mb-0 sm:flex-1'>
								<p>Son giris Tarihi: {item.progress.lastEnteredDate}</p>
								<p>Son giris Saati: {item.progress.lastEnteredTime}</p>
								<p>Unite: {item.progress.levelSection}</p>
								<p>Konu: {item.progress.levelChunk}</p>
							</section>
							<section className='sm:flex-1'>
								<p>Etüt günleri: {item.progress.penaltyPoint}</p>
								{/* <p>Zincir gün sayısı: {userCourse.userCourseProgresses.chainTimes}</p> */}
							</section>
							<div>
								{item.progress.courseComplete ? (
									<button className='p-3 m-2 bg-blue-100 rounded-md text-sm'>
										<Link className='btn btn-primary' to={`/course/${item.course.id}`}>
											Kursunuz tamamlanmıştır. Detay
										</Link>
									</button>
								) : (
									<button className='p-3 m-2 bg-green-100 rounded-md text-sm'>
										<Link className='btn btn-primary' to={`/course-detail/${item.course.id}`}>
											Bilgi kazanım detay ve devam
										</Link>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileCourses;
