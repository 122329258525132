import React from "react";
import CoursePage from "../../pages/CoursePage";
import LoggedInUnpayed from "./LoggedInUnpayed";
import LoggedInPayed from "./LoggedInPayed";

const MainDesc = () => {
	return (
		<>
			<div className='flex flex-col'>
				{/* <div className='heading'>
					<span>Zincir Okul</span>
				</div> */}
				{/* Section 1  */}
				<div className='flex flex-row  w-full bg-red-500 h-96 text-white '>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>Zincir Okul</div>
									<div className='text-xl'>Bir online güncelleme alan offline eğitim yeridir.</div>
									<div className=' pl-10'>
										<ul className='list-disc tex-small'>
											<li>Yazılım Mühendisi Cem Gökçe tarafından yapılmıştır.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>Eğitim</div>
									<div className='text-xl'>Satın aldığınız eğitim öğrenmek amaçlıdır.</div>
									<div className=' pl-10'>
										<ul className='list-disc tex-small'>
											<li>Yani sınava hazırlanmak veya ezber yapmak için değildir.</li>
											<li>Hayat boyu kullanmak için öğretiyoruz.</li>
											<li>Aldığınız eğitimin bizim açımızdan bir amacı olmalıdır.</li>
											<li>Günümüzde bir çok online eğitim satınmaktadır amaçları belirsizdir.</li>
										</ul>
									</div>

									{/* <div>
										Bu eğitim plaformu günümüzde var olan "Kurs veya Eğitim Cehennemi" olarak bilinen problemi çözmek
										için yapılmıştır.
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Section 2  */}
				<div className='flex flex-row  w-full bg-red-400 h-96 text-white '>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>Paydaşlar</div>
									<div className='text-xl'>Öğretmen, Eğitim ve Öğrenci </div>
									<div className=' pl-10 w-96'>
										<ul className='list-disc tex-small'>
											<li>Sonrada yetişecek Mentör Öğrenciler</li>
											<li>
												Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar
												etmiş olup kalıcı öğrenmeyi gerçekleştirir.
											</li>
										</ul>
									</div>

									{/* <div className='text-xl'>Mentör Öğrenci</div>
									<ul className='list-disc tex-small'>
										<li>
											Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar etmiş
											olup kalıcı öğrenmeyi gerçekleştirir.
										</li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>İlkemiz</div>
									<div className='text-xl'>Öğrenmeye odaklan.</div>
									<div className=' pl-10'>
										<ul className='list-disc tex-small'>
											<li>Kaynak arama öğremenlerimiz senin için en iyi kaynakları bulur.</li>
											<li>Öğrenci olarak görevin kaynak aramak değil ders çalışmak.</li>
											<li>
												Zincir okul olarak biz bu görevi öğretmenlerimeze veriyoruz ve kaynaklarını güncel tutmaları
												gerektiği
											</li>
											<li>
												Tamam dersi öğrendin ama hayat değişiyor baştan mı öğreniceksin hayır tabikide şimdi güncellenen
												kaynakalrı gözden geçir neler değişmiş hayatta.
											</li>
											<li>Güncellenen kaynakları gözden geçir.</li>
										</ul>
									</div>
									<div className='text-xs'>
										müdendis cem zincir okul projesi ile eğitim sistemine yeni bir bakış açısı getirmiştir.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Section 3  */}
				<div className='flex flex-row  w-full bg-red-300 h-96 text-white '>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>Paydaşlar</div>
									<div className='text-xl'>Öğretmen, Eğitim ve Öğrenci </div>
									<div className=' pl-10 w-96'>
										<ul className='list-disc tex-small'>
											<li>Sonrada yetişecek Mentör Öğrenciler</li>
											<li>
												Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar
												etmiş olup kalıcı öğrenmeyi gerçekleştirir.
											</li>
										</ul>
									</div>

									{/* <div className='text-xl'>Mentör Öğrenci</div>
									<ul className='list-disc tex-small'>
										<li>
											Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar etmiş
											olup kalıcı öğrenmeyi gerçekleştirir.
										</li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>İlkemiz</div>
									<div className='text-xl'>Öğrenmeye odaklan.</div>
									<div className=' pl-10'>
										<ul className='list-disc tex-small'>
											<li>Kaynak arama öğremenlerimiz senin için en iyi kaynakları bulur.</li>
											<li>Öğrenci olarak görevin kaynak aramak değil ders çalışmak.</li>
											<li>
												Zincir okul olarak biz bu görevi öğretmenlerimeze veriyoruz ve kaynaklarını güncel tutmaları
												gerektiği
											</li>
											<li>
												Tamam dersi öğrendin ama hayat değişiyor baştan mı öğreniceksin hayır tabikide şimdi güncellenen
												kaynakalrı gözden geçir neler değişmiş hayatta.
											</li>
											<li>Güncellenen kaynakları gözden geçir.</li>
										</ul>
									</div>
									<div className='text-xs'>
										müdendis cem zincir okul projesi ile eğitim sistemine yeni bir bakış açısı getirmiştir.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Section 4  */}
				<div className='flex flex-row  w-full bg-red-200 h-96 text-white '>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>Paydaşlar</div>
									<div className='text-xl'>Öğretmen, Eğitim ve Öğrenci </div>
									<div className=' pl-10 w-96'>
										<ul className='list-disc tex-small'>
											<li>Sonrada yetişecek Mentör Öğrenciler</li>
											<li>
												Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar
												etmiş olup kalıcı öğrenmeyi gerçekleştirir.
											</li>
										</ul>
									</div>

									{/* <div className='text-xl'>Mentör Öğrenci</div>
									<ul className='list-disc tex-small'>
										<li>
											Aldığı eğitimi bitiren öğrenci mentör olur ve öğrenci eğitmeye başlar ve öğrendikleri tekrar etmiş
											olup kalıcı öğrenmeyi gerçekleştirir.
										</li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					<div className='flex-1 p-2 m-2'>
						<div className='flex flex-col mt-20 w-full h-full p-4'>
							<div className=' flex flex-row justify-start'>
								<div className=''>
									<div className='text-3xl'>İlkemiz</div>
									<div className='text-xl'>Öğrenmeye odaklan.</div>
									<div className=' pl-10'>
										<ul className='list-disc tex-small'>
											<li>Kaynak arama öğremenlerimiz senin için en iyi kaynakları bulur.</li>
											<li>Öğrenci olarak görevin kaynak aramak değil ders çalışmak.</li>
											<li>
												Zincir okul olarak biz bu görevi öğretmenlerimeze veriyoruz ve kaynaklarını güncel tutmaları
												gerektiği
											</li>
											<li>
												Tamam dersi öğrendin ama hayat değişiyor baştan mı öğreniceksin hayır tabikide şimdi güncellenen
												kaynakalrı gözden geçir neler değişmiş hayatta.
											</li>
											<li>Güncellenen kaynakları gözden geçir.</li>
										</ul>
									</div>
									<div className='text-xs'>
										müdendis cem zincir okul projesi ile eğitim sistemine yeni bir bakış açısı getirmiştir.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className=''>
					<ol>
						<li>
							Surekli olarak calismanizi takiul ederek sonuc odakli bir egitim sistemi uzerinde hedefinize ulamanizi
							saglayan ve egitim muhendislik incelemesinden gecirildikten sonra olsuturulan egitim sitemidir.
						</li>
						<li>
							Burada ogren ve ogret mantigi kullanilarak ogrenmek icin degil ogretmek icin ogreniyoruz ve odak noktamizi
							iki guc kat arttirilmis bir calisma odagi saglamis oluyoruz.
						</li>
						<li>
							Bu sistem ile en onemli olan zamanimizi verimli kullanmis ve hedefimiz icin laylaylom vakit gecirmek
							yerine hedefine kitlenmis belirli bir zaman icerinde hedefine ulasicak egitimlere olanak sagliyoruz.
						</li>
						<li>Bu egitim sisteminin ilk kursu olan ingilizce egitimimizi ornek olarak.</li>
						<li>Hergun bir saat calismaniz beklenmektedir.</li>
						<li>90 gun olarak hazirlandi.</li>
						<li>Hergun 1 saat icerisinde calismanizi tamamlamaniz durumunda level alirsiniz.</li>
						<li>Haliyle 90 level de kursunuz bitmis olur</li>
						<li>Eger bir gun kursa girmezseniz leveliniz bazi matemetik hesabina gore duser ayni oyun gibi.</li>
						<li>
							Eger 1 saat icinde egitiminizi tamazsaniz level alamazsiniz. Bunun amaci zamaninizi bos gecirmemeniz.
						</li>
						<li>
							1 saat ten fazla ingilizce egitim alamiyorsunuz. Yani bunu boyle ayarladik.Cunku dil ogrenmek kas yaulmak
							gibi bir gunde cok calisiul six bag hayal ayni sekilde dil ogrenmek te oyle. O yuzden hergun bir saatini
							herkez ayirir ve bitrimeyi dener diye dusunuyoruz ama daha fazlasi icin kimse caba harcamaz ve gerekte
							yok.
						</li>
						<li>Tabi iceride mola ver butonu var 15 dakika mola yaulabilirsiniz.</li>
						<li>Yani azim ve disiullin herseyi ogrenebilirsiniz. Tabi dogru egitim ile.</li>
						<li>Egitim bitti ogrendin. Bu sefer kendine bes kisi bul.</li>
						<li>Onlara caboo yaul %20 indirim bizden hediye kursu satin almalari icin :)</li>
						<li>
							Ve surekli bizim belirledigimiz okuma metni ve bazi ornekler ile ingilizce 30 dakika konusma yaul ve
							belirledigin kisileri egit ve kendini zinde tut.
						</li>
						<li>bu aldigin 5 ksininde egitimi 90 gunde biter.</li>
						<li>
							ve 180 gunde ingilizce isini burada kapatiriyoruz artik ingilizce rahat bit sekilde konusan birisin. Bu
							guzel yapmis oludugun kaslari artik kaybetmemekte senin elinde.
						</li>
					</ol>
				</div> */}
			</div>
		</>
	);
};

export default MainDesc;
