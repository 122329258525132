import React, { useEffect, useState, useContext } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AuthContext from "../../store/auth-context";

const SectionAdd = ({ setNewSectionShow, courseId, section }) => {
	const [sectionName, setSectionName] = useState(null);
	const [sectionDesc, setSectionDesc] = useState(null);
	const [orderNo, setOrderNo] = useState(null);

	const authCtx = useContext(AuthContext);

	const handleSectionName = (e) => {
		console.log(e.target.value);
		setSectionName(e.target.value);
	};

	const handleSectionDesc = (e) => {
		console.log(e.target.value);
		setSectionDesc(e.target.value);
	};

	const handleOrderNo = (e) => {
		console.log(e.target.value);
		setOrderNo(e.target.value);
	};

	const handleSubmit = () => {
		const data = {
			name: sectionName,
			description: sectionDesc,
			courseId: courseId,
			orderNo: orderNo,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/sections/`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewSectionShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	useEffect(() => {
		if (section !== null) {
			setSectionName(section.name);
			setSectionDesc(section.description);
			setOrderNo(section.orderNo);
		} else {
			console.log("girdi");
			setSectionName("");
			setSectionDesc("");
			setOrderNo("");
		}
	}, [section]);

	//
	const handleUpdate = () => {
		const data = {
			name: sectionName,
			description: sectionDesc,
			courseId: courseId,
			orderNo: orderNo,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/sections/${section.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewSectionShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	const handleDelete = () => {

		fetch(`${process.env.REACT_APP_API_URL}/sections/${section.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
		})
			.then((response) => {
				console.log("Success:", response);
				setNewSectionShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};


	return (
		<div className="">
			<div className='flex flex-row justify-center'>
				<div className='flex flex-col justify-center gap-4 p-4'>
					{/* <div className='flex flex-row justify-between'>
						{section !== null ? (
							<div className='text-2xl semibold '>{section.orderNo}. Unite Güncelleme</div>
						) : (
							<div className='text-2xl semibold '>Unite Ekleme</div>
						)}

						<div>
							<XMarkIcon
								className='h-5 w-5 scale-150 cursor-pointer'
								onClick={() => {
									setNewSectionShow(false);
								}}
							/>
						</div>
					</div> */}
					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Adi : </div> */}
						<input
							placeholder='Lutfen unite adini giriniz'
							value={sectionName}
							onChange={handleSectionName}
							className='p-2 w-72 border border-black'
						/>
					</div>

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Aciklama : </div> */}
						<textarea
							placeholder='Lutfen unite aciklamasini giriniz'
							onChange={handleSectionDesc}
							value={sectionDesc}
							rows={5}
							className='p-2 h-32 w-72 border border-black'
						/>
					</div>
					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Sira no : </div> */}
						<input
							type='number'
							value={orderNo}
							placeholder='Lutfen unite sira numarasini giriniz'
							onChange={handleOrderNo}
							className='p-2 w-72 border border-black'
						/>
					</div>
					<div className='w-22 flex flex-row justify-center'>
						{section !== null ? (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleUpdate}>
								Güncelle
							</button>
						) : (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleSubmit}>
								Kayit Et
							</button>
						)}
						<button className='bg-red-200 m-2 p-2 w-32 rounded-md' onClick={handleDelete}>
								DELETE
							</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionAdd;
