import React from "react";
import {
	ArrowDownLeftIcon,
	ArrowLongLeftIcon,
	ArrowRightIcon,
	BeakerIcon,
	ArrowLongRightIcon,
	PlayIcon,
} from "@heroicons/react/24/solid";

const LoggedInUnpayed = () => {
	let arr = [{ name: "Zincir eğitimi nedir." }, { name: "Kurs yapılarına bir göz atalım" }];

	return (
		<div>
			<div>
				<div className='bg-white flex flex-row justify-between mx-3 border-4 border-black px-2 py-fit mr-52'>
					<div>
						<h1 className='text-3xl'>İngilizce Eğitim</h1>
					</div>
					<div className='flex flex-col text-xl'>
						<p className='overflow-visible mt-10 ml-12'>4.999 değil 5000 tl</p>
						<div className='w-80 -mt-36 mr-24 -mb-20'>
							<ArrowLongRightIcon />
						</div>
					</div>
					<div className='flex justify-center items-center pr-20 text-2xl '>
						<button className='rounded-full bg-green-400 px-2'>Satın Al</button>
					</div>
				</div>
			</div>
			{arr.map((item) => (
				<div>
					<div className='flex '>
						<div className='w-full h-80 flex justify-evenly'>
							<div className='flex items-center justify-evenly w-2/5 mt-20'>
								{item.name}
								<div className='flex h-20 w-60 pl-20'>
									<ArrowLongRightIcon />
								</div>
							</div>
							<div className='border border-black float-right mt-20 h-48 w-48 flex justify-center items-center rounded-xl'>
								<PlayIcon className='h-20 w-20 ' />
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default LoggedInUnpayed;
