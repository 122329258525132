import React from "react";
import { ArrowDownLeftIcon, ArrowLongLeftIcon, ArrowLongRightIcon, ArrowRightIcon, BeakerIcon } from '@heroicons/react/24/solid'
import InputBox from "./Inputbox"

const LoggedInPayed = () => {


    return (
        <div>
            <h1 className="font-bold text-4xl">Kursunuz</h1>
            <br />
            <div>
                <InputBox />
            </div>
        </div>
    );
}

export default LoggedInPayed;

