import React, { useEffect, useState, useContext } from "react";

const Dropdownlist = ({ content, handleSelected, title, selectedItem }) => {
	return (
		<div>
			<div className=''>
				<select className='p-1' value={selectedItem} onChange={handleSelected}>
					<option value='0'>{title}</option>
					{content}
				</select>
			</div>
		</div>
	);
};

export default Dropdownlist;
