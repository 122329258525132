import React, { useEffect, useState, useContext } from "react";
import Profile from "../components/Profile/Profile";
import AuthContext from "../store/auth-context";

const ProfilePage = () => {
	const authCtx = useContext(AuthContext);

	const [user, setUser] = useState(null);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		if (authCtx.token !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/users/single`, {
				method: "GET",
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();			
			console.log("user and courses course progress");
			console.log(data);
			setUser(data);
		}
	};

	return (
		<div className="page page-view">
			<Profile user={user} />
		</div>
	);
};

export default ProfilePage;
