import React, { useState } from "react";
import Modal from "../UI/Modal";

const EksAddKavramModal = ({ hadleCloseModal, submitModal, formData, setFormData }) => {
	
	const handleChange = (e) => {
		const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
		setFormData({ ...formData, [e.target.name]: value });
	};
	return (
		<Modal onClose={hadleCloseModal} title='Kavram Düzenleme'>
			<div className=''>
				<form onSubmit={submitModal} className='space-y-4 mb-10'>
					<div>
						<label htmlFor='firstName' className='block text-sm font-medium text-gray-700'>
							Adınız
						</label>
						<input
							type='text'
							name='name'
							id='name'
							value={formData.name}
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='lastName' className='block text-sm font-medium text-gray-700'>
							Soyadınız
						</label>
						<input
							type='text'
							name='surname'
							id='surname'
							value={formData.surname}
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div>
						<label htmlFor='email' className='block text-sm font-medium text-gray-700'>
							Email Adresiniz
						</label>
						<input
							type='email'
							name='email'
							id='email'
							disabled
							value={formData.email}
							required
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
							onChange={handleChange}
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='emailConfirmed' className='flex items-center'>
							<input
								type='checkbox'
								id='emailConfirmed'
								name='emailConfirmed'
								checked={formData.emailConfirmed}
								onChange={handleChange}
								className='mr-2 leading-tight'
							/>
							<span className='text-sm font-medium text-gray-600'>Email Onayı</span>
						</label>
					</div>
					<div className='mb-4'>
						<label htmlFor='birthday' className='block text-sm font-medium text-gray-600'>
							Birthday:
						</label>
						<input
							type='date'
							id='birthday'
							name='birthday'
							value={formData.birthday}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='homeTown' className='block text-sm font-medium text-gray-600'>
							Memleket:
						</label>
						<input
							type='text'
							id='homeTown'
							name='homeTown'
							value={formData.homeTown}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>

					<div className='mb-4'>
						<label htmlFor='occupation' className='block text-sm font-medium text-gray-600'>
							Meslek:
						</label>
						<input
							type='text'
							id='occupation'
							name='occupation'
							value={formData.occupation}
							onChange={handleChange}
							className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
						/>
					</div>
					<div className='mb-4'>
						<label htmlFor='phone' className='block text-sm font-medium text-gray-600'>
							Phone:
						</label>
						<input
							placeholder='Başında 0 olmadan 5382892872 gibi yazınınız.'
							type='tel'
							id='phone'
							name='phone'
							value={formData.phone}
							onChange={handleChange}
							className='mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500'
						/>
					</div>
					<div className=' flex gap-3'>
						<div className='mb-4 border p-1'>
							<label htmlFor='phoneConfirmed' className='flex items-center'>
								<input
									type='checkbox'
									id='phoneConfirmed'
									name='phoneConfirmed'
									checked={formData.phoneConfirmed}
									onChange={handleChange}
									className='mr-2 leading-tight'
								/>
								<span className='text-sm font-medium text-gray-600'>Telefon Onayı</span>
							</label>
						</div>
						<div className='mb-4 border p-1'>
							<label htmlFor='active' className='flex items-center'>
								<input
									type='checkbox'
									id='active'
									name='active'
									checked={formData.active}
									onChange={handleChange}
									className='mr-2 leading-tight'
								/>
								<span className='text-sm font-medium text-gray-600'>Kullanıcı Aktif</span>
							</label>
						</div>
						<div className='mb-4 border p-1'>
							<label htmlFor='managerConfirmed' className='flex items-center'>
								<input
									type='checkbox'
									id='managerConfirmed'
									name='managerConfirmed'
									checked={formData.managerConfirmed}
									onChange={handleChange}
									className='mr-2 leading-tight'
								/>
								<span className='text-sm font-medium text-gray-600'>Admin Onayı</span>
							</label>
						</div>
					</div>
					<div className='flex justify-center'>
						<button
							type='submit'
							className='w-3/4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							Düzenle
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default EksAddKavramModal;
