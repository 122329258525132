import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import ProfilePictureUpload from "./ProfilePictureUpload";

const ProfileSettings = () => {
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(1);
	const [user, setUser] = useState(null);
	const [errorPassword, setErrorPassword] = useState({ succes: false, message: "" });
	const [errorUser, setErrorUser] = useState({ succes: false, message: "" });
	const tabContentRefs = useRef([]);

	const authCtx = useContext(AuthContext);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		setLoading(true);
		if (authCtx.token !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/users/single`, {
				method: "GET",
				headers: {
					Authorization: `${authCtx.token}`,
					"Content-Type": "application/json",
				},
			});
			const data = await response.json();
			console.log("user and courses course progress");
			console.log(data);
			setFormData(data);
			setUser(data);

			// const objectURL = URL.createObjectURL(data.photo);
			// setImageSrc(objectURL);

			// try {
			// 	if (data.photo && data.photo instanceof Blob) {
			// 		console.log(`girdi`)
			// 		// Create an object URL from the received Blob
			// 		const objectURL = URL.createObjectURL(data.photo);

			// 		// Set the object URL as the image source
			// 		setImageSrc(objectURL);

			// 	}else {
			// 		console.log(`nothing`)
			// 	}
			// } catch (error) {
			// 	console.error("Error processing photo Blob:", error.message);
			// }
		}
		setLoading(false);
	};

	const handleTabClick = (tabIndex) => {
		setActiveTab(tabIndex);
		// Access the ref and focus on the corresponding tab content
		tabContentRefs.current[tabIndex].focus();
	};

	const [formData, setFormData] = useState({
		name: "",
		surname: "",
		email: "",
		birthday: "",
		homeTown: "",
		occupation: "",
		password: "",
		phone: "",
	});

	const [formPasswordData, setFormPasswordData] = useState({
		oldPassword: "",
		newPassword: "",
	});

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setErrorUser({ succes: false, message: "" });

		setFormData((prevData) => ({
			...prevData,
			[name]: name === "phone" ? formatPhoneNumber(value) : type === "checkbox" ? checked : value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		formData.photo = ``;

		fetch(`${process.env.REACT_APP_API_URL}/users`, {
			method: "PUT",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		})
			.then((response) => console.log(response.json()))
			.then((formData) => {
				console.log("Form submitted:", formData);
				setErrorUser({ succes: true, message: "Degisiklikler basari ile kayit edildi." });
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
				setErrorUser({ succes: false, message: "Bir hata olustu tekrar deneyin." });
			});
	};

	const handlePasswordChange = (e) => {
		const { name, value } = e.target;
		setErrorPassword({ succes: false, message: `` });

		setFormPasswordData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();

		fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
			method: "PUT",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formPasswordData),
		})
			.then((response) => response.json())
			.then((response) => {
				console.log("Form submitted:", response.message);
				console.log("Password changed successfully:", formPasswordData);
				setErrorPassword({ succes: response.succes, message: response.message }); // Reset error
			})
			.catch((error) => {
				setErrorPassword({ succes: false, message: error.message });
			});

		// Check if the old password is correct (you would typically do this on the server)
		// const correctOldPassword = "correctoldpassword"; // Replace with actual correct password

		// if (formPasswordData.oldPassword === correctOldPassword) {
		// 	// Password change logic (update on the server, etc.)
		// 	console.log("Password changed successfully:", formPasswordData.newPassword);
		// 	setErrorPassword(null); // Reset error
		// } else {
		// 	setErrorPassword("Incorrect old password. Please try again.");
		// }
	};

	const formatPhoneNumber = (phoneNumber) => {
		// Remove non-numeric characters from the phone number
		const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

		// Check if the phone number is empty or less than 10 digits
		if (!numericPhoneNumber || numericPhoneNumber.length < 10) {
			return phoneNumber; // Return as-is if it's not a valid phone number
		}

		// Format the phone number (adjust the format as needed)
		const formattedPhoneNumber = `(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(
			3,
			6
		)}-${numericPhoneNumber.slice(6, 10)}`;

		return formattedPhoneNumber;
	};

	return (
		<div>
		<h1 className="text-4xl font-bold">Ayarlar</h1>
		<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
		  {/* Sol Menü */}
		  <div className="w-full md:w-44">
			<div className="flex flex-col mt-20 space-y-2">
			  <button
				onClick={() => handleTabClick(1)}
				className={`p-2 mx-2 ${activeTab === 1 ? "bg-gray-300" : "bg-gray-100"} transition-colors duration-300 ease-in-out`}
			  >
				Profil Düzenleme
			  </button>
			  <button
				onClick={() => handleTabClick(2)}
				className={`p-2 mx-2 ${activeTab === 2 ? "bg-gray-300" : "bg-gray-100"} transition-colors duration-300 ease-in-out`}
			  >
				Şifre Değiştirme
			  </button>
			  <button
				onClick={() => handleTabClick(3)}
				className={`p-2 mx-2 ${activeTab === 3 ? "bg-gray-300" : "bg-gray-100"} transition-colors duration-300 ease-in-out`}
			  >
				Profil Fotoğrafı
			  </button>
			</div>
		  </div>
	  
		  {/* İçerik Bölümü */}
		  <section className="text-xl font-medium col-span-2">
			<div>
			  {!loading ? (
				<div
				  ref={(el) => (tabContentRefs.current[1] = el)}
				  tabIndex={1}
				  className={`p-4 ${activeTab === 1 ? "block" : "hidden"}`}
				>
				  {/* Profil Ayarları */}
				  <div>
					<h1 className="text-2xl font-semibold mb-6">Bio Ayarları</h1>
					<form onSubmit={handleSubmit}>
					  <div className="mb-4">
						<label htmlFor="name" className="block text-sm font-medium text-gray-600">İsim:</label>
						<input
						  type="text"
						  id="name"
						  name="name"
						  value={formData.name}
						  onChange={handleChange}
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  <div className="mb-4">
						<label htmlFor="surname" className="block text-sm font-medium text-gray-600">Soyisim:</label>
						<input
						  type="text"
						  id="surname"
						  name="surname"
						  value={formData.surname}
						  onChange={handleChange}
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  <div className="mb-4">
						<label htmlFor="birthday" className="block text-sm font-medium text-gray-600">Doğum Günü:</label>
						<input
						  type="date"
						  id="birthday"
						  name="birthday"
						  value={formData.birthday}
						  onChange={handleChange}
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  <div className="mb-4">
						<label htmlFor="homeTown" className="block text-sm font-medium text-gray-600">Memleket:</label>
						<input
						  type="text"
						  id="homeTown"
						  name="homeTown"
						  value={formData.homeTown}
						  onChange={handleChange}
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  <div className="mb-4">
						<label htmlFor="occupation" className="block text-sm font-medium text-gray-600">Meslek:</label>
						<input
						  type="text"
						  id="occupation"
						  name="occupation"
						  value={formData.occupation}
						  onChange={handleChange}
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  <div className="mb-4">
						<label htmlFor="phone" className="block text-sm font-medium text-gray-600">Telefon:</label>
						<input
						  type="tel"
						  id="phone"
						  name="phone"
						  value={formData.phone}
						  onChange={handleChange}
						  placeholder="Başında 0 olmadan yazınız."
						  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
						/>
					  </div>
	  
					  {errorUser.success ? (
						<p className="text-green-500 mb-4">{errorUser.message}</p>
					  ) : (
						<p className="text-red-500 mb-4">{errorUser.message}</p>
					  )}
	  
					  <button
						type="submit"
						className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
					  >
						Değişiklikleri Kaydet
					  </button>
					</form>
				  </div>
				</div>
			  ) : (
				<div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div>
			  )}
	  
			  <div
				ref={(el) => (tabContentRefs.current[2] = el)}
				tabIndex={2}
				className={`p-4 ${activeTab === 2 ? "block" : "hidden"}`}
			  >
				{/* Şifre Değiştir */}
				<div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
				  <h1 className="text-2xl font-semibold mb-6">Şifre Değiştir</h1>
				  <form onSubmit={handlePasswordSubmit}>
					<div className="mb-4">
					  <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-600">
						Eski şifrenizi giriniz:
					  </label>
					  <input
						type="password"
						id="oldPassword"
						name="oldPassword"
						value={formPasswordData.oldPassword}
						onChange={handlePasswordChange}
						className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
					  />
					</div>
	  
					<div className="mb-4">
					  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-600">
						Yeni şifre giriniz:
					  </label>
					  <input
						type="password"
						id="newPassword"
						name="newPassword"
						value={formPasswordData.newPassword}
						onChange={handlePasswordChange}
						className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
					  />
					</div>
	  
					{errorPassword.success ? (
					  <p className="text-green-500 mb-4">{errorPassword.message}</p>
					) : (
					  <p className="text-red-500 mb-4">{errorPassword.message}</p>
					)}
	  
					<button
					  type="submit"
					  className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
					>
					  Şifreyi Değiştir
					</button>
				  </form>
				</div>
			  </div>
	  
			  <div
				ref={(el) => (tabContentRefs.current[3] = el)}
				tabIndex={3}
				className={`p-4 ${activeTab === 3 ? "block" : "hidden"}`}
			  >
				<ProfilePictureUpload user={user} />
			  </div>
			</div>
		  </section>
		</div>
	  </div>
	  
	);
};

export default ProfileSettings;
