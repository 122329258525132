import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const TestContent = ({ chunkItem }) => {
	const [question, setQuestion] = useState("");
	const [answers, setAnswers] = useState([]);
	const [answer, setAnswer] = useState("");
	const [allText, setAlText] = useState([]);
	const [questionEdit, setQuestionEdit] = useState(true);
	const [correctAnswer, setCorrectAnswer] = useState("");
	const [disableSave, setDisableSave] = useState(false);

	const [testChunkItem, setTestChunkItem] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (chunkItem.testContent !== null) {
			setLoading(true);
			getTests(chunkItem.testContent.link);
		}
		console.log("Test chunkItem");
		console.log(chunkItem);
	}, []);

	const getTests = async (link) => {
		const response = await fetch(link);
		const data = await response.json();
		setTestChunkItem(chunkItem.testContent);
		setAlText(JSON.parse(data));
		setLoading(false);
	};

	const handleAnswers = () => {
		let newAnswers = [];
		newAnswers = [...answers, answer];
		setAnswers(newAnswers);
		console.log(newAnswers);
		setAnswer("");
	};

	const handleAnswer = (e) => {
		setAnswer(e.target.value);
	};

	const answerDelete = (abc) => {
		let newArray = answers.filter((item) => item !== abc);
		setAnswers(newArray);
	};

	const handleCorrectAnswer = (e) => {
		setCorrectAnswer(e.target.value);
	};

	const handleQuestionEdit = () => {
		setQuestionEdit(!questionEdit);
	};
	const handleQuestion = (e) => {
		setQuestion(e.target.value);
	};

	const saveQuestion = async () => {
		setLoading(true);
		let questionTotal = { question, answers, correctAnswer };
		const data = {
			title: `Test`,
			content: JSON.stringify(questionTotal),
			chunkItemId: chunkItem.id,
		};

		fetch(`${process.env.REACT_APP_API_URL}/testContents`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				console.log("Success:", response);
				setTestChunkItem(response);
				setAlText(questionTotal);
				setDisableSave(true);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
		setLoading(false);
	};

	const deleteHandler = async () => {
		setLoading(true);

		fetch(`${process.env.REACT_APP_API_URL}/testContents/${testChunkItem.id}`, {
			method: "delete",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			.then((response) => {
				console.log("Success:", response);
				setTestChunkItem(null);
				setQuestion("");
				setAnswers("");
				setAlText([]);
				setQuestionEdit(true);
				setCorrectAnswer("");
				setDisableSave(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
		setLoading(false);
	};

	return (
		<>
			{loading == true ? (
				<div>Loading</div>
			) : testChunkItem == null ? (
				<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 ml-20'>
					<div className='flex flex-col justify-center'>
						<div className='flex flex-row justify-center gap-2 m-2'>
							<input
								placeholder='Lutfen soru giriniz'
								className={`w-72`}
								value={question}
								onChange={handleQuestion}
								disabled={!questionEdit}
							/>
							<button
								onClick={handleQuestionEdit}
								className={`w-20 h-10 ${questionEdit ? `bg-green-100` : `bg-red-100`}`}
							>
								{questionEdit ? <div>Ekle</div> : <div>Duzenle</div>}
							</button>
						</div>
						<div>
							<div>Soru Sekli</div>
							{question !== "" ? <div>{question} ?</div> : <div>Lutfen soruyu giriniz</div>}
						</div>

						<div className='flex flex-row justify-center gap-2 m-2'>
							<input placeholder='Lutfen cevap giriniz' className='w-72' value={answer} onChange={handleAnswer} />
							<button onClick={handleAnswers} className='w-20 h-10 bg-red-400'>
								Cevap ekle
							</button>
						</div>
					</div>

					<div>
						<div>Answers</div>
						{answers.length !== 0 ? (
							<>
								<div>
									{answers.map((item) => (
										<div>
											{item}{" "}
											<button onClick={() => answerDelete(item)} className='bg-red-200'>
												Cikar
											</button>
										</div>
									))}
								</div>
								{answers.length !== 0 && (
									<div>
										<select onChange={handleCorrectAnswer}>
											<option value='yok'>Dogru Cevap Seciniz</option>
											{answers.map((item) => (
												<option value={item}>{item}</option>
											))}
										</select>
									</div>
								)}
							</>
						) : (
							<div>Lutfen cevaplari ekleyin</div>
						)}
					</div>
					<div
						className={`h-10 w-32 ${disableSave ? `bg-red-300 cursor-default ` : `bg-green-300 cursor-pointer`}`}
						onClick={saveQuestion}
						disabled={disableSave}
					>
						Soruyu kayit Et
					</div>
				</div>
			) : (
				<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 ml-20'>
					<div className='flex bg-red-300 w-fit cursor-pointer' onClick={deleteHandler}>
						Delete
						<span>
							<XMarkIcon className='h-6 w-6' />
						</span>
					</div>
					{allText.length !== 0 && (
						<div className='flex flex-col justify-center'>
							<div>
								<span>{chunkItem.orderNo}</span>. içerik
							</div>
							<div>
								Soru: <span className='font-bold'>{allText.question} ?</span>
							</div>
							Cevaplar
							<div>
								{allText.answers.map((item) => (
									<div className='flex flex-col gap-2'>
										<div className='flex flex-row gap-3'>
											<input type='checkbox' id={chunkItem.id} />
											{item}
										</div>
									</div>
								))}
							</div>
							<div>
								<span>Dogru Cevap:</span>
								<span>{allText.correctAnswer}</span>
							</div>
							<div>
								<span>Ozet:</span>
								<span>{chunkItem.summary}</span>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default TestContent;
