import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Courses = (props) => {
	const navigate = useNavigate();

	const { id, name, description } = props;

	return (
		<>
			<div>
				<div className='flex flex-col sm:flex-row border border-blue-200 '>
					<div className='w-full p-2'>
						<img src='https://picsum.photos/seed/picsum/300/200' className='w-full sm:w-auto' alt='...' />
					</div>
					<div className='flex flex-col w-full justify-around p-2'>
						<div className='flex-grow'>
							<h6 className='text-2xl text-red-500'>{name}</h6>
							<p>{description.slice(0, 50)}..</p>
						</div>
						<div className='flex-shrink m-2'>
							<Link className='btn btn-primary' to={`/course/${id}`}>
								<button className='p-4 bg-green-100 rounded-md'>İncele</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Courses;
