import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { XMarkIcon } from "@heroicons/react/24/solid";

const VideoContent = ({ chunkItem }) => {
	const [file, setFile] = useState(null);
	const [name, setName] = useState("");
	const fileInputRef = useRef(null);
	const [videoChunkItem, setVideoChunkItem] = useState(null);

	useEffect(() => {
		if (chunkItem.videoContent !== null) {
			setVideoChunkItem(chunkItem.videoContent);
		}else {
			setVideoChunkItem(null)
		}
		console.log(`chunkItem videoContent`);
		console.log(chunkItem.videoContent);
	}, [chunkItem]);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const onFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const onNameChange = (e) => {
		setName(e.target.value);
	};

	const deleteHandler = async () => {
		console.log(`id`);
		console.log(chunkItem.videoContent.id);

		fetch(`${process.env.REACT_APP_API_URL}/videoContents/${videoChunkItem.id}`, {
			method: "DELETE",
			mode: "cors",
			headers: {
				// Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			//body: JSON.stringify(data),
		})
			//.then((response) => console.log(response.json()))
			.then((response) => {
				console.log("Success:", response);
				setVideoChunkItem(null);
				setName("");
				setFile(null);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	const handleUpload = async () => {
		if (file !== null) {
			const formData = new FormData();
			formData.append("video", file);
			formData.append("name", name);
			formData.append("chunkItemId", chunkItem.id);

			try {
				const response = await axios.post(`${process.env.REACT_APP_API_URL}/videoContents`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				console.log(response);
				setVideoChunkItem(response.data);
			} catch (error) {
				console.error("Error uploading video: ", error);
			}
		} else {
			alert("Lütfen dosya seçiniz");
		}
	};

	return (
		<>
			{videoChunkItem == null ? (
				<>
					<div className='flex flex-col justify-center  w-full m-20'>
						<div className='flex flex-row gap-2'>
							<div className='font-semibold'>İçerik {chunkItem.orderNo}:</div>
							<span className='font-semibold'>{chunkItem.name}</span>
						</div>
						{/* <div className='flex flex-col gap-4 p-4 m-4'>
							<input type='file' accept='video/*' onChange={onFileChange} />
							<input type='text' placeholder='Video Title' value={name} onChange={onNameChange} />
							<button onClick={handleUpload}>Upload</button>
						</div> */}
						<div className='bg-red-100 p-5 max-w-sm mx-auto rounded-lg shadow-md'>
							<input
								type='text'
								value={name}
								onChange={onNameChange}
								placeholder='Video Başlığı'
								className='p-2 border border-red-300 rounded w-full'
							/>
							<input type='file' ref={fileInputRef} onChange={onFileChange} className='hidden' />
							<button
								onClick={handleButtonClick}
								className='mt-3 p-2 bg-red-500 text-white rounded hover:bg-red-600 w-full'
							>
								Dosya Seç
							</button>
							<button
								onClick={handleUpload} // Bu buton asıl yükleme işlemini tetiklemeli
								className='mt-3 p-2 bg-green-500 text-white rounded hover:bg-green-600 w-full'
							>
								Video Yükle
							</button>
						</div>
					</div>
				</>
			) : (
				<div className='flex flex-col gap-4 mt-5 justify-center bg-green-50 ml-20'>
					<div className='flex bg-red-300 w-fit cursor-pointer' onClick={deleteHandler}>
						Delete
						<span>
							<XMarkIcon className='h-6 w-6' />
						</span>
					</div>
					<div className='flex flex-row gap-2'>
						<div>Video</div>
						<div className='font-semibold'>{chunkItem.orderNo}-</div>
						Konu: <span className='font-semibold'>{chunkItem.name}</span>
					</div>
					<video className='w-72 h-52' controls>
						<source src={videoChunkItem.url} type='video/mp4' />
						URL kaybolmus galiba.
					</video>
					<div>
						Video name: <span className='font-semibold'>{videoChunkItem.name}</span>
					</div>
				</div>
			)}
		</>
	);
};

export default VideoContent;
