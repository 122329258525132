import React, { useState, useEffect } from "react";

const Kelime = ({ chunkItem }) => {
	const [savedWords, setSavedWords] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (chunkItem.wordContent !== null) {
			setLoading(true);
			getWords(chunkItem.wordContent.link);
		}
		console.log("Word chunkItem");
		console.log(chunkItem);
	}, []);

	const getWords = async (link) => {
		console.log(`link`);
		console.log(link);
		const response = await fetch(link);
		const data = await response.json();
		setSavedWords(JSON.parse(data));
		setLoading(false);
	};
	return (
		<div>
			{loading == true ? (
				<div>Loading</div>
			) : (
				<div>
					<ul className='mt-4 mb-4'>
						Kelimeler
						{savedWords.map((word, index) => (
							<li
								key={index}
								className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md flex flex-row justify-between'
							>
								<span>
									<strong>sira:</strong> {word.order},<strong>Word:</strong> {word.word}, <strong>Meaning:</strong>{" "}
									{word.meaning}, <strong>Example Sentence:</strong> {word.exampleSentence},
									<strong>Example Sentence Meaning:</strong> {word.exampleSentenceMeaning}
								</span>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
export default Kelime;
