import React from "react";
import Mentor from "../components/Mentor/Mentor";

const MentorPage = () => {
	return (
		<div>
			<Mentor />
		</div>
	);
};

export default MentorPage;
