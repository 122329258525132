import React, { useState, useEffect, useContext } from "react";

const NotFoundPage = () => {
	return (
		<>
			<div className=' '>
				<div className='flex flex-col justify-center items-center h-screen bg-yellow-50'>
					<div className='text-3xl'>Bizde böyle bir sayfa yok kanka, yada bir hata oluştu.</div>
					<div className='text-3xl'>Hatasız kul, errorsuz internet sayfası olmaz.</div>
					<div className='text-3xl'>Sen ne yapıyorsan en iyisi bi daha dene.</div>
				</div>
				{/* <div className="flex flex-col justify-center items-center h-screen bg-yellow-50">
				<div className="text-3xl font-semibold">Neyse madem geldin bir fıkra anlatıyım</div>
				<div className="text-xl">
                    <p>Bir gün bir çoçuk demişki bu ne ya</p>
                    <p>Kankası da demiş bu tere ya</p>
                    <p>Oda demiş ya bu tere ne demek</p>
                    <p>Kankası demiş ya amına kodum bu tere ya işte</p>
                    <p>Oda demiş ya amına koyduğum bu tere ne onu bilmiyorum</p>
                    <p>Kankası demiş ya amına kodum oğlu bu tere ya işte ekmeğe sürüyon</p>
                    <p>Oda demiş kanka neyse siktir et sen doğru ya, zincir okul eğitimi değilsin, en iyisi biz ders çalışmaya devam edelim biz .</p>
                </div>
			</div> */}
			</div>
		</>
	);
};

export default NotFoundPage;
