import React, { useState } from "react";
import Chunks from "./Chunks";
import { PencilSquareIcon } from "@heroicons/react/24/solid";

const SectionTek = ({ item, setSection }) => {
	const { name, description, courseId, id, orderNo } = item;
	const [showChunks, setShowChunks] = useState(false);

	return (
		<>
			<div className='flex flex-col md:flex-row'>
				<div className='m-1 flex flex-col justify-start mt-4'>
					<span
						onClick={() => {
							setSection(item);
						}}
						className='cursor-pointer bg-white  '
					>
						<PencilSquareIcon className='text-red-300 w-6 h-6' />
					</span>
				</div>
				<div>
					<div
						className='m-1 border border-gray-300 bg-blue-500 rounded-lg flex flex-row p-1'
						onClick={() => {
							setShowChunks(!showChunks);
						}}
					>
						<div className='w-auto min-w-[200px] '>
							<div className='flex'>
								<span className='font-semibold'>{orderNo}. Ünite</span>
							</div>
							<div>{name}</div>
							{/* <div>{description}</div> */}
						</div>
					</div>
					{showChunks && <Chunks sectionId={id} />}
				</div>
			</div>
		</>
	);
};

export default SectionTek;
