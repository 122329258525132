import React, { useEffect, useState } from "react";
import {
  ChevronUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import Pagination from "./Pagination";
import InfoPopup from "../InfoPopup";

import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useAsyncDebounce,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import matchSorter from "match-sorter";

import useSearchParamsState from "../util-useSearchParamsState";

//#region filters settings
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex items-baseline gap-x-2">
      <span className="text-gray-700">Search: </span>
      <input
        type="text"
        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}
// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}
// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
//#endregion

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const SortableTable = ({
  columns,
  data,
  onClick,
  search = true,
  topPagination = true,
  bottomPagination = true,
  compactPagination = false,
  selectable = false,
  setSelected = () => {},
  deleteButton = false,
  deleteHandler = () => {},
  queryParam,
}) => {
  useEffect(() => {
    console.log("rendertimes");
  }, []);

  const [queryParamState, setQueryParamState] = useSearchParamsState(
    queryParam,
    false
  );

  const [_selected, _setSelected] = useState(null);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  //default filter selection
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      // Cell: EditableCell,
    }),
    []
  );

  const initialState = queryParamState ? JSON.parse(queryParamState) : {};
  let initialSorting = [];
  if (initialState.sc) {
    initialSorting = [{ id: initialState.sc, desc: initialState.sd }];
  }

  //calling react-table from library function
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    visibleColumns,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
      initialState: {
        sortBy: initialSorting,
        pageIndex: JSON.parse(queryParamState).pi || 0,
        pageSize: JSON.parse(queryParamState).ps || 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    selectable && deleteButton
      ? (hooks) => {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps, selectedFlatRows }) => (
                <div className="flex">
                  <TrashIcon
                    className="icon hover:cursor-pointer hover:text-red-400"
                    onClick={() => deleteHandler(selectedFlatRows)}
                  />
                  <div className="block w-5">
                    <InfoPopup
                      icon={<InformationCircleIcon />}
                      content={
                        <div className="text-sm text-black">
                          Selected row will be delete.
                        </div>
                      }
                    />
                  </div>
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      : selectable
      ? (hooks) => {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="">
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      : (hooks) => {}
  );

  const sortedColumn = sortBy[0] || [];

  useEffect(() => {
    setSelected(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    if (queryParam) {
      setQueryParamState(
        JSON.stringify({
          pi: pageIndex,
          ps: pageSize,
          sc: sortedColumn.id,
          sd: sortedColumn.desc,
        })
      );
    }
  }, [pageIndex, pageSize, sortedColumn.id, sortedColumn.desc]);

  useEffect(() => {
    if (queryParam) {
      setQueryParamState(
        JSON.stringify({
          pi: 0,
          ps: pageSize,
          sc: sortedColumn.id,
          sd: sortedColumn.desc,
        })
      );
      gotoPage(0);
    }
  }, [data]);

  useEffect(() => {
    if (queryParam) {
      const state = JSON.parse(queryParamState || "{}");
      if (state.ps) {
        setPageSize(state.ps);
      }
      if (state.pi) {
        gotoPage(state.pi);
      }
    }
  }, [queryParamState]);

  return (
    <div className="core-control sortable-table max-w-xs sm:max-w-md md:max-w-xl lg:max-w-3xl xl:max-w-5xl " >
      {/* Top pagination Start */}
      {topPagination ? (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageCount={pageCount}
          setPageSize={setPageSize}
          rows={rows}
          gotoPage={gotoPage}
          compactPagination={compactPagination}
        />
      ) : null}
      {/* Top pagination End */}

      {/* Table Start */}
      <div className="flex flex-col min-w-full overflow-x-auto overflow-hidden scroll-m-9">
        <table
          className="min-w-full divide-y divide-gray-200"
          {...getTableProps()}
        >
          <thead className="bg-gray-50">
            {search && (
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  className="px-0 text-left bg-white border-black"
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            )}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className="px-2 py-2 font-normal text-left text-white bg-gray-800 border text-l border-r-gray-500"
                    {...column.getHeaderProps()}
                  >
                    <div
                      className="flex items-center justify-between"
                      {...column.getSortByToggleProps({ title: undefined })}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ChevronDownIcon />
                            ) : (
                              <ChevronUpIcon />
                            )
                          ) : (
                            <ChevronUpDownIcon />
                          )}
                        </span>
                      )}
                    </div>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {topPagination || bottomPagination ? (
            <tbody className="bg-white" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps({
                      onClick: (e) => {
                        _setSelected(row);
                        if (onClick) onClick(row.original, e, row);
                      },
                    })}
                    className={`${
                      onClick
                        ? "clickable-row hover:bg-sky-50 cursor-pointer"
                        : ""
                    } ${_selected === row ? "selected-row" : ""}`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className="px-2 py-1 border border-spacing-1"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell", { editable: true })}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody
              className="block overflow-x-auto overflow-y-auto max-h-80 bg-white"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className="px-2 py-1 border border-spacing-1 whitespace-nowrap"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>

      {/* Table End */}

      {/* Bottom pagination Start */}
      {bottomPagination ? (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageCount={pageCount}
          setPageSize={setPageSize}
          rows={rows}
          gotoPage={gotoPage}
          compactPagination={compactPagination}
        />
      ) : null}
      {/* Bottom pagination End */}
    </div>
  );
};

export default SortableTable;
