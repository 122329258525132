import React, { useState, useEffect, useContext } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AuthContext from "../../store/auth-context";

const ChunkItemAdd = ({ setNewItemShow, chunkId, chunkItem }) => {
	const [name, setName] = useState(null);
	const [desc, setDesc] = useState(null);
	const [itemType, setItemType] = useState(null);
	const [orderNo, setOrderNo] = useState(null);

	const authCtx = useContext(AuthContext);

	const handleChunknName = (e) => {
		console.log(e.target.value);
		setName(e.target.value);
	};

	const handleChunkDesc = (e) => {
		console.log(e.target.value);
		setDesc(e.target.value);
	};
	const handleChunkItemOrderNo = (e) => {
		console.log(e.target.value);
		setOrderNo(parseInt(e.target.value));
	};

	const handleChunkItemType = (e) => {
		console.log(e.target.value);
		setItemType(e.target.value);
	};

	const handleSubmit = () => {
		const data = {
			name: name,
			description: desc,
			type: itemType,
			orderNo: orderNo,
			chunkId: chunkId,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/chunkItems`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	useEffect(() => {
		if (chunkItem !== null) {
			setName(chunkItem.name);
			setDesc(chunkItem.description);
			setOrderNo(chunkItem.orderNo);
			setItemType(chunkItem.type);
		} else {
			setName("");
			setDesc("");
			setOrderNo("");
			setItemType("");
		}
	}, [chunkItem]);

	//
	const handleUpdate = () => {
		const data = {
			name: name,
			description: desc,
			chunkId: chunkId,
			type: itemType,
			orderNo: orderNo,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/chunkItems/${chunkItem.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	const handleDelete = () => {

		fetch(`${process.env.REACT_APP_API_URL}/chunkItems/${chunkItem.id}`, {
			method: "DELETE",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${authCtx.token}`,
			},
		})
			.then((response) => {
				console.log("Success:", response);
				setNewItemShow(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	return (
		<>
			<div className='flex flex-row justify-center'>
				<div className='flex flex-col justify-center gap-4  p-4'>
					{/* <div className='flex flex-row justify-between'>
						{chunkItem !== null ? (
							<div className='text-2xl semibold '>{chunkItem.orderNo}. İçerik Güncelleme</div>
						) : (
							<div className='text-2xl semibold '>İçerik Ekleme</div>
						)}

						<div>
							<XMarkIcon
								className='h-5 w-5 scale-150 cursor-pointer '
								onClick={() => {
									setNewItemShow(false);
								}}
							/>
						</div>
					</div> */}
					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Adi </div> */}
						<input
							placeholder='Lutfen icerik adini giriniz'
							value={name}
							onChange={handleChunknName}
							className='p-2 w-72 border border-black'
						/>
					</div>

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Aciklama</div> */}
						<textarea
							placeholder='Lutfen icerik aciklamasi giriniz'
							value={desc}
							onChange={handleChunkDesc}
							rows={5}
							className='p-2 h-32 w-72 border border-black'
						/>
					</div>

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32'>Sira no</div> */}
						<input
							type='number'
							placeholder='Lutfen sira numarasi giriniz'
							value={orderNo}
							onChange={handleChunkItemOrderNo}
							className='p-2 w-72 border border-black'
						/>
					</div>

					<div className='flex flex-row gap-4'>
						{/* <div className='flex flex-col justify-center w-32 '>Icerik Tipi : </div> */}
						<select name='cars' id='cars' value={itemType} className='p-2 border border-black' onChange={handleChunkItemType}>
							<option value='yok'>Bir kategori seciniz</option>
							<option value='video'>Video</option>
							<option value='test'>Test</option>
							<option value='reading'>Okuma</option>
							<option value='summary'>Ozet</option>
							<option value='word'>Kelime</option>
						</select>
					</div>

					<div className='w-22 flex flex-row justify-center'>
						{chunkItem !== null ? (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleUpdate}>
								Güncelle
							</button>
						) : (
							<button className='bg-green-200 p-2 w-32 rounded-md' onClick={handleSubmit}>
								Kayit Et
							</button>
						)}
						<button className='bg-red-200 p-2 m-2 w-32 rounded-md' onClick={handleDelete}>
								DELETE
							</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChunkItemAdd;
