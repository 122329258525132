import React, { useState, useEffect } from "react";
import Student from "./Student";

const CourseStudents = ({ courseId }) => {
	const [users, setUsers] = useState([]);
	const [openModal, setOpenModal] = useState(true);

	useEffect(() => {
		getCourseStudent();
	}, []);

	const getCourseStudent = async () => {
		if (courseId !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/userCourses/${courseId}/course`);
			const data = await response.json();
			console.log("users");
			console.log(data);
			setUsers(data.Cusers);
		}
	};

	return (
		<div>
			<div className='text-2xl ml-4 my-2'>Öğrenciler</div>
			{users.length !== 0 ? (
				users.map((item) => <Student user={item} />)
			) : (
				<div>Hic bir ogrenci bu kursa kayit olmamistir.</div>
			)}
		</div>
	);
};

export default CourseStudents;
