import React, { useEffect, useState, useContext, useRef } from "react";
import AuthContext from "../../store/auth-context";

const CourseRules = ({ course }) => {
	const [penaltyRules, setPenaltyRules] = useState([]);
	const [penaltyContents, setPenaltyContents] = useState([]);
	const [openNewRule, setOpenNewRule] = useState(false);
	const [openNewContent, setOpenNewContent] = useState(false);

	const pointRuleRef = useRef();
	const timeRuleRef = useRef();
	const timesRuleRef = useRef();

	const pointContentRef = useRef();
	const chunkRef = useRef();
	const sectionRef = useRef();
	const contentRef = useRef();
	const [summaryCheck, setSummaryCheck] = useState(false);
	const [videoyCheck, setVideoyCheck] = useState(false);
	const [wordCheck, setWordCheck] = useState(false);
	const [testCheck, setTestCheck] = useState(false);
	const [readingCheck, setReadingCheck] = useState(false);

	const summaryHandler = () => {
		setSummaryCheck(!summaryCheck);
		console.log(summaryCheck);
	};
	const readingHandler = () => {
		setReadingCheck(!readingCheck);
		console.log(readingCheck);
	};
	const videoHandler = () => {
		setVideoyCheck(!videoyCheck);
		console.log(videoyCheck);
	};
	const testHandler = () => {
		setTestCheck(!testCheck);
		console.log(testCheck);
	};
	const wordHandler = () => {
		setWordCheck(!wordCheck);
		console.log(wordCheck);
	};

	const authCtx = useContext(AuthContext);

	useEffect(() => {
		console.log(authCtx);
		console.log(penaltyContents);
		console.log(penaltyRules);
	}, [authCtx, penaltyContents, penaltyRules]);

	useEffect(() => {
		getPenaltyRules();
		getPenaltyContents();
	}, [openNewRule, openNewContent]);

	const getPenaltyRules = async () => {
		if (course !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/coursePenaltyRules/${course.id}/course`);
			const data = await response.json();
			console.log("rules");
			console.log(data);
			setPenaltyRules(data);
		}
	};

	const getPenaltyContents = async () => {
		if (course !== null) {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/coursePenaltyContents/${course.id}/course`);
			const data = await response.json();
			console.log("penaltycontents");
			console.log(data);
			setPenaltyContents(data);
		}
	};

	const addPenaltyContent = () => {
		console.log(pointContentRef.current.value);
		console.log(chunkRef.current.value);
		console.log(sectionRef.current.value);
		console.log(contentRef.current.value);

		const data = {
			point: pointContentRef.current.value,
			chunk: chunkRef.current.value,
			section: sectionRef.current.value,
			video: videoyCheck,
			word: wordCheck,
			test: testCheck,
			summary: summaryCheck,
			reading: readingCheck,
			content: contentRef.current.value,
			courseId: course.id,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/coursePenaltyContents`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setOpenNewContent(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};
	const addPenaltyRule = () => {
		console.log(pointRuleRef.current.value);
		console.log(timeRuleRef.current.value);
		console.log(timesRuleRef.current.value);

		const data = {
			point: pointRuleRef.current.value,
			time: timeRuleRef.current.value,
			times: timesRuleRef.current.value,
			courseId: course.id,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/coursePenaltyRules`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				setOpenNewRule(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};
	return (
		<>
			{openNewRule && (
				<div className='m-10'>
					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36 '>Zaman Sekli</label>
						<input
							className='p-1 border border-blue-300'
							type='text'
							id='time'
							required
							ref={timeRuleRef}
							placeholder='Gunde, haftada'
						/>
					</div>
					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Count</label>
						<input className='p-1 border border-blue-300' type='number' id='times' required ref={timesRuleRef} />
						<label className='w-36'> kadar girilmez ise</label>
					</div>
					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Puan</label>
						<input className='p-1 border border-blue-300' type='number' id='point' required ref={pointRuleRef} />
						<label className='w-36'> ceza puani alir.</label>
					</div>
					<div
						className='bg-green-100 border rounded-md p-2  w-36 h-10 flex justify-center m-2 cursor-pointer'
						onClick={() => {
							addPenaltyRule();
						}}
					>
						Ekle
					</div>
				</div>
			)}

			{openNewContent && (
				<div className='m-10'>
					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Puan</label>
						<input className='p-1 border border-blue-300' type='number' id='point' required ref={pointContentRef} />
						<label className='w-36'> ceza puani alir.</label>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Chunk</label>
						<input className='p-1 border border-blue-300' type='number' id='chunk' required ref={chunkRef} />
						<label className='w-36'> ceza puani alir.</label>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Section</label>
						<input className='p-1 border border-blue-300' type='number' id='section' required ref={sectionRef} />
						<label className='w-36'> ceza puani alir.</label>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Videolar</label>
						<input
							className='p-1 border border-blue-300'
							type='checkbox'
							id='video'
							checked={videoyCheck}
							onChange={videoHandler}
						/>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Kelimeler</label>
						<input
							className='p-1 border border-blue-300'
							type='checkbox'
							id='word'
							checked={wordCheck}
							onChange={wordHandler}
						/>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Ozet metin</label>
						<input
							className='p-1 border border-blue-300'
							type='checkbox'
							id='summary'
							checked={summaryCheck}
							onChange={summaryHandler}
						/>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Okuma Metni</label>
						<input
							className='p-1 border border-blue-300'
							type='checkbox'
							id='reading'
							checked={readingCheck}
							onChange={readingHandler}
						/>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Test</label>
						<input
							className='p-1 border border-blue-300'
							type='checkbox'
							id='test'
							checked={testCheck}
							onChange={testHandler}
						/>
					</div>

					<div className='flex gap-2 mx-4 p-1'>
						<label className='w-36'>Content</label>
						<input className='p-1 border border-blue-300' type='text' id='content' ref={contentRef} />
					</div>

					<div
						className='bg-green-100 border rounded-md p-2  w-36 h-10 flex justify-center m-2 cursor-pointer'
						onClick={() => {
							addPenaltyContent();
						}}
					>
						Ekle
					</div>
				</div>
			)}

			{!openNewContent && !openNewRule && (
				<>
					<div className='m-2  p-2'>
						<div
							className='bg-green-100 border rounded-md p-2  w-36 h-10 flex justify-center m-2 cursor-pointer'
							onClick={() => setOpenNewRule(!openNewRule)}
						>
							Yeni Kural
						</div>
						{penaltyRules.length !== 0 ? (
							penaltyRules.map((item) => (
								<div>
									<span className='bg-red-300'>{item.times}</span> <span className='bg-red-300'>{item.time}</span> kez
									giris yapilmaz ise
									<span className='bg-red-300'>{item.point}</span> ceza puani olucaktir.
								</div>
							))
						) : (
							<div>Herhangi bir kural olusturulmamis.</div>
						)}
					</div>
					<div className='m-2 p-2'>
						<div
							className='bg-green-100 border rounded-md p-2  w-36 h-10 flex justify-center m-2 cursor-pointer'
							onClick={() => setOpenNewContent(!openNewContent)}
						>
							Yeni Ceza Icerigi
						</div>
						{penaltyContents.length !== 0 ? (
							penaltyContents.map((item) => (
								<div className="border-2 m-1 p-1 border-blue-300 w-72">
									<span className='bg-red-300'>{item.point}</span> ceza puani var ise
									<br/>
									En son {item.chunk != 0 ? <span>{item.chunk}  chunk tekrari yapilicak.</span> : <></>}
									<br/>
									En son {item.section != 0 ? <span>{item.section}  section tekrari yapilicak.</span> : <></>}
									<div> Bu tekrarlarin icinde
										<span className="bg-red-300">{item.video ? ` Videolar ` : ""}</span>
										<span className="bg-red-300">{item.word ? ` Kelimeler ` : ""}</span>
										<span className="bg-red-300">{item.summary ? ` Ozetler ` : ""}</span>
										<span className="bg-red-300">{item.reading ? ` Okuma metinleri ` : ""}</span>
										<span className="bg-red-300">{item.test ? ` Testler ` : ""}</span>
										tekrar edilicek.
									</div>
								</div>
							))
						) : (
							<div>Herhangi bir ceza icerigi olusturulmamis.</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default CourseRules;
