import React, { useState } from "react";

const ContentCreator = ({ setContent, content }) => {
	// const [content, setContent] = useState([]);
	const [newContent, setNewContent] = useState({ type: "header", text: "", children: [] });
	const [editPath, setEditPath] = useState([]); // editPath ekledik
	const [isAddingSubelement, setIsAddingSubelement] = useState(false);

	const findContentByPath = (path, contentList) => {
		let current = { children: contentList };
		for (let index of path) {
			current = current.children[index];
		}
		return current;
	};

	const addOrUpdateContent = () => {
		const updatedContent = [...content];
		let parent = { children: updatedContent };

		for (let i = 0; i < editPath.length - 1; i++) {
			parent = parent.children[editPath[i]];
		}

		if (editPath.length === 0 || editPath[editPath.length - 1] === parent.children.length) {
			parent.children.push({ ...newContent, children: [] });
		} else {
			parent.children[editPath[editPath.length - 1]] = {
				...newContent,
				children: parent.children[editPath[editPath.length - 1]].children,
			};
		}

		setContent(updatedContent);
		setEditPath([]);
		setNewContent({ type: "header", text: "", children: [] });
		setIsAddingSubelement(false);
	};

	const editContent = (path) => {
		setEditPath(path);
		setIsAddingSubelement(false);
		const toEdit = findContentByPath(path, content);
		setNewContent({ ...toEdit });
	};

	const deleteContent = (path) => {
		const updatedContent = [...content];
		let parent = { children: updatedContent };

		for (let i = 0; i < path.length - 1; i++) {
			parent = parent.children[path[i]];
		}

		parent.children.splice(path[path.length - 1], 1);

		setContent(updatedContent);
	};

	const addSubelement = (path) => {
		setEditPath([...path, findContentByPath(path, content).children.length]);
		setIsAddingSubelement(true);
		setNewContent({ type: "subheader", text: "", children: [] });
	};

	const renderContentWithNumbers = (contentList, path = []) => {
		return contentList.map((item, index) => {
			const currentPath = [...path, index];
			const prefix = currentPath.join(".");

			return (
				<div key={index} className='mb-2   '>
					<div className='flex justify-between border border-black m-2'>
						<div>
							{item.type === "header" && <h1 className='text-3xl text-red-400'>{`${prefix}-) ${item.text}`}</h1>}
							{item.type === "subheader" && (
								<h2 className='text-xl text-blue-400 ml-2 m-2'>{`${prefix}-)  ${item.text}`}</h2>
							)}
							{item.type === "paragraph" && <p className='text-black ml-5 m-2'>{`${prefix}-) ${item.text}`}</p>}
						</div>
						<div className=''>
							<button onClick={() => editContent(currentPath)} className='bg-yellow-500 text-white p-1 rounded m-2 '>
								Düzenle
							</button>
							<button onClick={() => deleteContent(currentPath)} className='bg-red-500 text-white rounded p-1 m-2'>
								Sil
							</button>
							{item.type !== "paragraph" && (
								<button onClick={() => addSubelement(currentPath)} className='bg-green-500 text-white rounded p-1'>
									Altına Ekle
								</button>
							)}
						</div>
					</div>
					<div className=''>
						{item.children && item.children.length > 0 && renderContentWithNumbers(item.children, currentPath)}
					</div>
				</div>
			);
		});
	};

	const renderForm = () => {
		return (
			<div className='mb-4'>
				<select
					name='type'
					value={newContent.type}
					onChange={(e) => setNewContent({ ...newContent, type: e.target.value })}
					className='border border-gray-300 p-2 mr-2'
				>
					{!isAddingSubelement && <option value='header'>Başlık</option>}
					<option value='subheader'>Alt Başlık</option>
					<option value='paragraph'>Paragraf</option>
				</select>
				<input
					type='text'
					name='text'
					value={newContent.text}
					onChange={(e) => setNewContent({ ...newContent, text: e.target.value })}
					className='border border-gray-300 p-2'
					placeholder='İçerik girin'
				/>
				<button onClick={addOrUpdateContent} className='bg-blue-500 text-white p-2 ml-2'>
					{editPath.length > 0 ? "Güncelle" : "Ekle"}
				</button>
			</div>
		);
	};

	return (
		<div className='p-4'>
			{renderForm()}
			<div>{renderContentWithNumbers(content)}</div>
		</div>
	);
};

export default ContentCreator;
