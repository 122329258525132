import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { ArrowLongLeftIcon, ArrowLongRightIcon, ClockIcon } from "@heroicons/react/24/solid";

import { Link } from "react-router-dom";

const CourseProgress = () => {
	const [course, setCourse] = useState(null);
	const authCtx = useContext(AuthContext);
	const [courseGoals, setGoals] = useState([]);
	const params = useParams();
	const { id } = params;
	const [openUnits, setOpenUnits] = useState({});
	const [expanded, setExpanded] = useState({
		objectives: false,
		method: false,
		audience: false,
	});

	const toggleUnit = (index) => {
		setOpenUnits((prev) => ({ ...prev, [index]: !prev[index] }));
	};

	const toggleExpansion = (section) => {
		setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
	};

	useEffect(() => {
		console.log(authCtx);
		getLastChunkItems();
	}, []);

	useEffect(() => {
		if (course !== null && course.goals !== null) {
			setGoals(JSON.parse(course.goals));
		} else {
			setGoals([]);
		}
	}, [course]);

	const getLastChunkItems = async () => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/userCourseProgresses/${id}`, {
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
		});
		const data = await response.json();
		console.log("chunkItems");
		console.log(data);
		setCourse(data.courseUserCourse);
		setOpenUnits(data.courseUserCourse.sections);
	};

	return (
		<>
			{course !== null ? (
				<>
					<div className='container mx-auto p-4'>
						<div className='text-red-500 text-xl'>
							Kırmızı rakamları takip ederek eğitim sürecinizi inceleyebilirsin.
						</div>
						{/* birinci Row */}
						{/* <div className='flex flex-col sm:flex-row mb-4'>
							<div className='md:w-full p-2'>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<div className='flex justify-around '>
										<div className='flex justify-center items-center'>
											<div className=' text-3xl font-semibold p-2 flex justify-center'>
												<span className='text-red-500 text-3xl'>1- </span>
												Sıradaki Level
											</div>
											
											<div className=' bg-green-500 text-white font-bold rounded-full h-16 w-16 flex justify-center items-center m-2 shadow'>
												<span>{course.sections[0].orderNo}</span>
												<span>{course.sections[0].chunks[0].orderNo}</span>
											</div>

											
										</div>

										<div className='w-2/3 flex flex-col bg-red-50 rounded-lg p-1'>
											<div className='flex flex-col items-center'>
												<span>Ünite: {course.sections[0].orderNo}</span>
												<span>Konu: {course.sections[0].chunks[0].orderNo}</span>{" "}
											</div>
											<div className='flex flex-row justify-center '>
												<button className='bg-blue-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 w-fit m-3'>
													<Link className='btn btn-primary' to={`/course-start/${id}`}>
														Bilgi Kazanım Devam
													</Link>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						{/* birinci Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<div className='flex justify-center items-center'>
										<div className='font-semibold p-2 flex justify-center text-xl sm:text-2xl md:text-3xl'>
											<span className='text-red-500 text-3xl'>1- </span>
											Sıradaki Level
										</div>
										{/* Yeşil Arka Fonlu Yuvarlak Div */}
										<div className=' bg-green-500 text-white font-bold rounded-full h-16 w-16 flex justify-center items-center m-2 shadow'>
											<span>{course.sections[0].orderNo}</span>
											<span>{course.sections[0].chunks[0].orderNo}</span>
										</div>

										{/* Seviye Artışı Göstergesi */}
										{/* <div className='text-green-600'>
												→ <span>{course.sections[0].orderNo}</span>.<span>{course.sections[0].chunks[0].orderNo}</span>.
											</div> */}
									</div>
								</div>
							</div>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-red-50 rounded-lg p-1'>
									<div className='flex flex-col items-center'>
										<span>Ünite: {course.sections[0].orderNo}</span>
										<span>Konu: {course.sections[0].chunks[0].orderNo}</span>{" "}
									</div>
									<div className='flex flex-row justify-center '>
										<button className='bg-blue-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105 w-fit m-3'>
											<Link className='btn btn-primary' to={`/course-start/${id}`}>
												Bilgi Kazanım Devam
											</Link>
										</button>
									</div>
								</div>
							</div>
						</div>
						{/* ikinci Row */}
						<div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<h2 className='text-2xl font-bold mb-2'>{course.name}</h2>
									<p>{course.description}</p>
								</div>
							</div>
							<div className='md:w-1/2 p-2 '>
								<div className='bg-white shadow-lg rounded-lg p-4 h-full'>
									<div className='text-center font-semibold text-xl sm:text-2xl md:text-3xl'>
										<span className='text-red-500 text-3xl'>2- </span>
										Ünite İlerlemesi
									</div>
									<div className='w-full bg-gray-300 rounded-full h-2.5 mt-2'>
										<div
											className='bg-blue-600 h-2.5 rounded-full'
											style={{ width: `${course.sections[0].chunks[0].orderNo * 10}%` }}
										></div>
									</div>
								</div>
							</div>
						</div>

						{/* Üçüncü Row */}
						<div className='flex md:flex-row flex-col'>
							<div className='md:w-1/2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4'>
									<div className=' ml-2 sm:ml-10 mb-3 font-semibold text-xl sm:text-2xl md:text-3xl'>
										<span className='text-red-500 text-3xl '>3- </span>
										<span className="text-center ml-2 text-2xl">İçerikler</span>
									</div>
									{course.sections.map((section, index) => (
										<div key={index} className='p-2 mb-4 w-fit'>
											<div>
												<h4
													onClick={() => toggleUnit(index)}
													className='text-lg font-semibold cursor-pointer bg-gray-200 p-4 rounded-xl w-full sm:min-w-[300px] sm:w-auto'
												>
													Ünite{" "}
													<span>
														{section.orderNo}: {section.name}
													</span>
												</h4>
											</div>
											{openUnits[index] && (
												<div>
													<div className='flex m-2 overflow-hidden'>
														<span className='w-fit sm:min-w-[100px] flex justify-center bg-red-200 px-1 text-sm rounded-xl '>
															Kazanım
														</span>
														<span className='flex justify-center mx-2'>
															{/* Assuming ArrowLongRightIcon is a component */}
															<ArrowLongRightIcon className='w-4 h-4' />
														</span>
														<span className='pl-3'> {section.description}</span>
													</div>
													<div className='pl-2 sm:pl-5'>
														{section.chunks.map((chunk, idx) => (
															<div
																key={idx}
																className='flex flex-col sm:flex-row my-3 border border-gray-200 rounded-lg p-2 min-w-[100px] overflow-hidden'
															>
																<div className='flex flex-col sm:flex-row gap-2 text-xl  sm:text-base'>
																	<div className='w-full sm:min-w-[100px] '>
																		{section.orderNo}.{chunk.orderNo}. {chunk.name}
																	</div>
																	<div className='flex flex-row '>
																		<span className='flex justify-center items-center sm:min-w-[100px] bg-red-200 px-1 text-sm rounded-xl'>
																			Kazanım
																		</span>
																		<span className='flex justify-center items-center mx-2'>
																			{/* Assuming ArrowLongRightIcon is a component */}
																			<ArrowLongRightIcon className='w-4 h-4' />
																		</span>
																		<span className='w-full sm:min-w-[100px] text-sm flex justify-center items-center'>
																			{chunk.description}
																		</span>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							</div>
							<div className='md:w-1/2 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col justify-between h-full'>
									<div className={`${expanded.method ? "max-h-full" : "max-h-48"} overflow-hidden`}>
										<h3 className='font-semibold mb-2 text-xl sm:text-2xl md:text-3xl'>
											<span className='text-red-500 text-3xl'>4- </span>
											Çalışma Şekli
										</h3>

										<div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												<span>{course.totalCompleteTimeNumber}</span> <span>{course.totalCompleteTimeType}</span>{" "}
												tamamlanacaktır.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												Her <span>{course.courseTimeType}</span> <span>{course.courseTimeNumber}</span> kere
												calisilicakitr.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												1 kerede <span>{course.chunkTimeNumber}</span> <span>{course.chunkTimeType}</span>{" "}
												calisilicaktir.
											</div>
										</div>
									</div>
									<button
										onClick={() => toggleExpansion("method")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.method ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* Cem Gökçe başlangıç */}

					{/* <div className='flex flex-row justify-around page'>
						<div>{course.name}</div>
						<div className='text-3xl'>LEVEL</div>
						<div className='w-20 h-20 bg-green-400 rounded-full flex flex-col justify-center pl-8 text-3xl'>0</div>
						<div className='flex flex-col justify-center'>
							<div>Bolum 1 </div>
							<div>Konu 1 </div>
						</div>
						<div>Başlangıç Bugün</div>
					</div> */}

					{/* <div className='font-bold pl-10 mt-10 mb-5'>Bu {course.courseTimeType} yapılcaklar</div> */}

					{/* <div className='flex gap-10 pleace-content-around pl-10 border-4 mb-10 p-3'>
						<div className='flex flex-col gap-5 pl-40'>
							<div className='font-bold'>
								{course.courseTimeType} içinde {course.courseTimeNumber} kez çalışılıcak.{" "}
							</div>
							<div>
								{course.sections.map((item) => (
									<>
										<div>
											{item.orderNo}.{item.name}
										</div>
										{item.chunks.length !== 0 ? (
											item.chunks.map((itemkonu) => (
												<div className='ml-3 border-2 border-r-500'>
													<div>
														{item.orderNo}.{itemkonu.orderNo}.{itemkonu.name}
													</div>{" "}
													<div className='font-semibold'>
														Harcanacak zaman: {course.chunkTimeNumber} {course.chunkTimeType}
													</div>
												</div>
											))
										) : (
											<></>
										)}
									</>
								))}
							</div>
						</div>
						<div className='flex flex-col flex-auto pt-10 '>
							<button className={`border p-2 pl-4 pr-4 bg-green-500 rounded-2xl self-center`}>
								<Link className='btn btn-primary' to={`/course-start/${id}`}>
									Bilgi kazanım devam
								</Link>
							</button>
						</div>
					</div> */}

					{/* <div className='font-bold pl-10 mt-10 mb-5'>Çalışma şekli</div>
					<div className='flex gap-10 pleace-content-around pl-10 border-4 mb-10'>
						<div className='flex flex-col gap-5 font-bold'>
							<div>Toplam Zaman : </div>
							<div>Calisma sekli : </div>
							<div>Calisma Eforu : </div>
						</div>
						<div className='flex flex-col gap-5 pl-40'>
							<div>
								{course.courseUserCourse.totalCompleteTimeNumber} {course.courseUserCourse.totalCompleteTimeType}{" "}
								tamamlanicak.
							</div>
							<div>
								{course.courseUserCourse.courseTimeType} {course.courseUserCourse.courseTimeNumber} kere calisilicak.
							</div>
							<div>
								{" "}
								Bir kerede {course.courseUserCourse.chunkTimeNumber} {course.courseUserCourse.chunkTimeType}{" "}
								calisilicak.
							</div>
						</div>
					</div> */}

					{/* Hedefler */}
					{/* <div className='font-bold pl-10 mt-10 mb-5'>Hedef</div>
					<>
						<div className='flex flex-row border-4'>
							{courseGoals.length !== 0 ? (
								<div>
									<ul className='list-disc ml-10 font-bold'>
										{courseGoals.map((item) => (
											<li key={item.orderNo}>{item.goal}</li>
										))}
									</ul>
								</div>
							) : (
								<ul className='list-disc ml-10 font-bold'>
									<li> Kurs hedefleri tanimlanmamistir.</li>
								</ul>
							)}
						</div>
					</> */}
				</>
			) : (
				<div>Loading</div>
			)}
		</>
	);
};

export default CourseProgress;

{
	/* <div className='flex flex-col md:flex-row mb-4'>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col h-full '>
									<h3 className='text-xl font-semibold mb-2'>
										<span className='text-red-500 text-3xl'>1-</span>
										Kurs Amaçları
									</h3>
									<div className={`${expanded.objectives ? "max-h-full" : "max-h-32"} overflow-hidden`}>
										<ul className='list-disc pl-5'>
											{courseGoals.lenght !== 0 &&
												courseGoals.map((objective, index) => (
													
													<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
														<span>{objective.goal}</span>
													</div>
												))}
										</ul>
									</div>
									<button
										onClick={() => toggleExpansion("objectives")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.objectives ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col justify-between h-full'>
									<div className={`${expanded.method ? "max-h-full" : "max-h-48"} overflow-hidden`}>
										<h3 className='text-xl font-semibold mb-2'>
											<span className='text-red-500 text-3xl'>2- </span>
											Çalışma Şekli
										</h3>

										<div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												<span>{course.totalCompleteTimeNumber}</span> <span>{course.totalCompleteTimeType}</span>{" "}
												tamamlanacaktır.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												Her <span>{course.courseTimeType}</span> <span>{course.courseTimeNumber}</span> kere
												calisilicakitr.
											</div>
											<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
												1 kerede <span>{course.chunkTimeNumber}</span> <span>{course.chunkTimeType}</span>{" "}
												calisilicaktir.
											</div>
										</div>
									</div>
									<button
										onClick={() => toggleExpansion("method")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.method ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
							<div className='md:w-1/3 p-2 flex flex-col'>
								<div className='bg-white shadow-lg rounded-lg p-4 flex flex-col h-full justify-between'>
									<div className={`${expanded.audience ? "max-h-full" : "max-h-48"} overflow-hidden`}>
										<h3 className='text-xl font-semibold mb-2'>
											<span className='text-red-500 text-3xl'>3-</span>
											Kimler Katılabilir
										</h3>
										<p>{course.audience}</p>
										<div className='bg-gray-100 p-2 mt-2 border border-gray-300 rounded-md font-bold'>
											<span>Türkçe okuma yazma bilmeniz yeterlidir.</span>
										</div>
									</div>
									<button
										onClick={() => toggleExpansion("audience")}
										className='mt-2 text-blue-500 hover:text-blue-700 text-sm font-semibold'
									>
										{expanded.audience ? "Daha Az Göster" : "Daha Fazla Göster"}
									</button>
								</div>
							</div>
						</div> */
}
