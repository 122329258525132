import React from "react";
import ReactDOM from "react-dom/client";
import "./tailwind.css";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-context";
import ScrollToTop from "./store/util/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AuthContextProvider>
		<BrowserRouter>
			<ScrollToTop />
			<App />
		</BrowserRouter>
	</AuthContextProvider>
);
