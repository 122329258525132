import React, { useState, useEffect } from "react";

function Pagination({
	previousPage,
	nextPage,
	canNextPage,
	canPreviousPage,
	pageSize,
	pageIndex,
	pageOptions,
	pageCount,
	setPageSize,
	rows,
	gotoPage,
	compactPagination,
}) {
	const initialPageNumber = 5;

	const [pageNumberLimit, setPageNumberLimit] = useState(initialPageNumber);
	const [minPageNumberLimit, setMinPageNumberLimit] = useState(1);
	const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(initialPageNumber);

	useEffect(() => {
		const pivot = parseInt(pageIndex / pageNumberLimit);
		setMinPageNumberLimit(1 + (pivot * pageNumberLimit));
		setMaxPageNumberLimit((pivot * pageNumberLimit) + pageNumberLimit);
	}, [pageIndex]);

	
	return (
		<div className='flex items-center justify-between '>
			<div className='flex justify-between flex-1 sm:hidden'>
				<button
					type='button'
					className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
				>
					Previous
				</button>
				<button
					type='button'
					className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
					onClick={() => nextPage()}
					disabled={!canNextPage}
				>
					Next
				</button>
			</div>
			<div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
				{/* Pagination medium adn upper size start */}
				<div className='pagination'>
					<span className='ml-4'>
						<span>Showing </span>
						<select
							className='my-1 pl-0 pr-0  mr-0 w-12 bg-right border-none bg-inherit font-semibold focus:border-indigo-200 focus:ring focus:ring-[#E1E9EB]'
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value));
							}}
						>
							{[5,10, 20, 50, 100, 200].map((pageSize, i) => (
								<option key={i} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
						of <b>{rows.length}</b>
						<span className='ml-1 text-3xl bold'>|</span>
					</span>
					<span className='ml-2'>
						{!compactPagination ? (
							<button
								className='px-2 py-2 border-none bg-inherit text-brand-primary-700 disabled:text-gray-700'
								onClick={() => {
									gotoPage(0);
								}}
								disabled={!canPreviousPage}
							>
								{/* <ChevronDoubleLeftIcon className="w-3 h-3 font-bold text-brand-primary-700" aria-hidden="true" /> */}
								<span className='mr-2 '>{"<"}</span>
								<span className='w-3 h-3'>First</span>
							</button>
						) : (
							<></>
						)}
						<button
							className='px-2 py-2 border-none text-brand-primary-700 disabled:text-gray-700 bg-inherit'
							onClick={previousPage}
							disabled={!canPreviousPage}
						>
							{compactPagination ? <span className='mr-2 '>{"<"}</span> : <></>}
							<span>Previous</span>
						</button>
						{!compactPagination ? (
							pageOptions.map((i) => {
								return i < maxPageNumberLimit && i + 1 >= minPageNumberLimit ? (
									<button
										key={i}
										className={`ml-4 ${pageIndex == i ? " font-semibold" : null}`}
										onClick={() => {
											gotoPage(i);
										}}
									>
										{i + 1}
									</button>
								) : null;
							})
						) : (
							<></>
						)}
						<button
							className={`px-2 py-2 border-none text-brand-primary-700 disabled:text-gray-700 bg-inherit ${( !compactPagination ? 'ml-4' : '')}`}
							onClick={nextPage}
							disabled={!canNextPage}
						>
							<span>Next</span>
							{compactPagination ? <span className='ml-2 '>{">"}</span> : <></>}
						</button>
						{!compactPagination ? (
							<button
								className='px-2 py-2 border-none bg-inherit text-brand-primary-700 disabled:text-gray-700'
								onClick={() => {
									gotoPage(pageCount - 1);
								}}
								disabled={!canNextPage}
							>
								<span className='sr-only'>Last</span>
								{/* <ChevronDoubleRightIcon className="w-3 h-3 font-bold text-brand-primary-700" aria-hidden="true" />*/}
								<span className='w-3 h-3'>Last</span>
								<span className='ml-2 '>{">"}</span>
							</button>
						) : (
							<></>
						)}
					</span>
				</div>
			</div>
		</div>
	);
}

export default Pagination;
