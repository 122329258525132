import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../store/auth-context";

const CoursePublish = ({ setState, course }) => {
	const [price, setPrice] = useState();

	const [priceChange, setPriceChange] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const authCtx = useContext(AuthContext);

	const toggleCheckbox = () => {
        if (course.active != !isActive) {
			setPriceChange(true);
		} else {
			setPriceChange(false);
		}
		setIsActive(!isActive);
	};

	const handlePrice = (e) => {
		console.log(course.price);
		console.log(e.target.value);
		if (course.price != e.target.value) {
			console.log(true);
			setPriceChange(true);
		} else {
			console.log(false);
			setPriceChange(false);
		}
		setPrice(e.target.value);
	};

	const handleSubmitCourseUpdate = () => {
		const data = {
			active: isActive,
			price: price,
		};

		console.log(data);

		fetch(`${process.env.REACT_APP_API_URL}/courses/${course.id}`, {
			method: "PUT",
			mode: "cors",
			headers: {
				Authorization: `${authCtx.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => console.log(response.json()))
			.then((data) => {
				console.log("Success:", data);
				console.log("data");
				console.log(data);
				setState(false);
			})
			.catch((error) => {
				console.error("ErrorBurdan:", error);
			});
	};

	useEffect(() => {
		if (course !== null && course.price !== null) {
			setPrice(course.price);
		}
        if (course !== null && course.active !== null) {
			setIsActive(course.active);
		}
		console.log("course");
		console.log(course);
	}, [course]);

	return (
		<>
			<div className='flex flex-col mt-20 gap-4 '>
				<div className='flex justify-center'>
					<div className='flex border-2 text-3xl border-blue-500 rounded-lg p-3 w-auto'>
						<span className='text-blue-500 font-semibold mr-2'>₺</span>
						<input
							type='number'
							value={price}
							onChange={handlePrice}
							placeholder='Fiyat girin'
							className='flex-grow outline-none'
						/>
					</div>
				</div>
				<div className='flex justify-center'>
					<label className='inline-flex items-center mt-3'>
						<input
							type='checkbox'
							className='form-checkbox h-5 w-5 text-gray-600'
							checked={isActive}
							onChange={toggleCheckbox}
						/>
						<span className='ml-2 text-gray-700 w-auto break-words'>
							Kurs Aktiflik (Bütün kursunuzu tamamladıktan sonra aktif olarak güncelleyiniz)
						</span>
					</label>
				</div>
				<div className='w-22 flex flex-row justify-center'>
					{priceChange ? (
						<button className='bg-green-400 p-2 w-32 rounded-md cursor-pointer' onClick={handleSubmitCourseUpdate}>
							Yayınla
						</button>
					) : (
						<button className='bg-red-400 p-2 w-32 rounded-md cursor-default'>Fiyat Aynı</button>
					)}
				</div>
			</div>
		</>
	);
};

export default CoursePublish;
