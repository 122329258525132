import { ArrowLongRightIcon, PlayIcon } from "@heroicons/react/24/solid";
import React from "react";

const InputBox = (props) => {
	let list = [
		{ text: "Kurs içerisinde nasıl çalışacağınız anlatılmıştır." },
		{ text: "Belirli bir arac gerece ihtiyac olucak kagit kalem onlarda kursun basinda anlatiliyor." },
		{
			text: "Ilk gunde ingilizce calismiyoruz sadece nasil calismamamiz gerekiyor bu anlatiliyor ve ikinci gun asil ingilizce calismaya baslaniyor.",
		},
		{
			text: "Hic bilmeyen birisine gore duzenlenmistir ama siz biraz biliyorsunuzdur mesala ama soyle dusunun ahmet diye bir arkadasiniz var ve bayadir gorusmuyorsunuz ve 10 yil gormeseniz yine akliniza gelmez o yuzden bilmek yetmez yani aklimizda olmasi gerekir herseyin, o yuzden biliyor olsaniz bile calismaya devam etmeniz temennimiz. :) tabi bazilarida boyle degildir mesale furkanlar gibi ‘we go dance’",
		},
	];

	const FikraBox = (props) => {
		return (
			<div>
				<div className=' border-black border-2 flex'>
					<div className='mx-8'>
						<div className='text-red-600 text-2xl'>
							<h1>Başlık</h1>
						</div>
						<div>
							<p>{props.text}</p>
						</div>
						<tfoot align='right'>
							<button>Devamını oku...</button>
						</tfoot>
					</div>
				</div>
				<br />
			</div>
		);
	};
	return (
		<div>
			<div className='border-black flex w-full h-full justify-between border-2'>
				<div className='flex items-center content-center'>
					<h2 className='flex bg-green-400 text-5xl flex-wrap h-3/6 p-2 items-center content-center ml-2'>Resim</h2>
				</div>
				<div className='flex justify-items-center w-3/6'>
					<ul>
						{list.map((item) => (
							<li className='list-disc'>{item.text}</li>
						))}
					</ul>
				</div>
				<div className='flex justify-center items-center pr-20 text-2xl'>
					<button className='bg-yellow-400 rounded-full p-4'>Başla</button>
				</div>
			</div>
			<br />
			<hr />
			<br />
			<div>
				<h1 className='font-bold text-black text-4xl'>Fikra's</h1>
				<br />
				<FikraBox text='Komik komik yazilarwe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dance we go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go' />
				<FikraBox text='Komik komik yazilarwe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dance we go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go' />
				<FikraBox text='Komik komik yazilarwe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dance we go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go dancewe go' />
			</div>
		</div>
	);
};
export default InputBox;
